import { Tooltip } from 'antd';
import LocalStorage from './localStorage';
import moment from 'moment';

// activity completion statuses
export const STATUS = {
  completed: 'Completed',
  inProgress: 'In Progress',
};

// activity acceptance statuses
export const ACCEPTANCE_STATUS = {
  accept: 'accept',
  stopActivty: 'stop_activiti',
};

// table per page item nums
export const PER_PAGE_ITEM = 15;

export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('granules_Alliance') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    user: user.user_details,
    role: user.user_details?.role?.role_name,
    loggedIn: loginUser !== '',
  };
};

// column renderer for table
export const columnRenderer = (
  text,
  hasTooltip,
  showIndicator = false,
  classes,
  nullvalue = 'NA',
) => {
  return hasTooltip ? (
    <Tooltip title={text}>
      <p
        className={
          classes
            ? `${classes} overflow-hidden font-segoe-ui text-ellipsis whitespace-nowrap`
            : 'overflow-hidden text-xs font-segoe-ui text-ellipsis whitespace-nowrap'
        }
      >
        {text || nullvalue}
      </p>
    </Tooltip>
  ) : (
    <>
      <p
        className={
          classes
            ? `${classes} font-segoe-ui`
            : 'text-xs font-segoe-ui'
        }
      >
        {text || nullvalue}
      </p>
      {showIndicator && (
        <div className="absolute top-0 left-0 h-full border-l-[4px] border-primary-green"></div>
      )}
    </>
  );
};

// header renderer for table
export const headerRenderer = (
  text,
  hasTooltip,
  ellipsis = false,
  nullvalue = 'NA',
) => {
  return hasTooltip && ellipsis ? (
    <Tooltip title={text}>
      <label className="overflow-hidden text-xs font-semibold text-ellipsis whitespace-nowrap font-segoe-ui text-tertiary-placeholder">
        {text || nullvalue}
      </label>
    </Tooltip>
  ) : hasTooltip ? (
    <Tooltip title={text}>
      <label className="text-xs font-semibold font-segoe-ui text-tertiary-placeholder">
        {text || nullvalue}
      </label>
    </Tooltip>
  ) : ellipsis ? (
    <label className="overflow-hidden text-xs font-semibold text-ellipsis whitespace-nowrap font-segoe-ui text-tertiary-placeholder">
      {text || nullvalue}
    </label>
  ) : (
    <label className="text-xs font-semibold font-segoe-ui text-tertiary-placeholder">
      {text || nullvalue}
    </label>
  );
};

//formatted date for table cell
export const getFormattedDate = (date) => {
  return date ? moment(date).format('DD-MMM-YYYY') : '-';
};

//get dates difference in months
export const getDateDifference = (d1, d2) => {
  const date1 = moment(d1['$d']);
  const date2 = moment(d2['$d']);
  return date1.diff(date2, 'month');
};

//acceptance status label
export const getAcceptanceStatusLabel = (status) => {
  switch (status) {
    case ACCEPTANCE_STATUS.accept:
      return 'Accpeted';
    case ACCEPTANCE_STATUS.stopActivty:
      return 'Activity Stopped';
    default:
      return '-';
  }
};

//acceptance status classes
export const getAcceptanceStatusClasses = (status) => {
  switch (status) {
    case ACCEPTANCE_STATUS.accept:
      return 'bg-[#ECFDF3] rounded-md w-fit px-3 py-0.5 font-semibold text-primary-green text-2xs';
    case ACCEPTANCE_STATUS.stopActivty:
      return 'bg-[#FFE4E4] rounded-md w-fit px-3 py-0.5 font-semibold text-primary-red text-2xs';
    default:
      return 'rounded-md w-fit px-3 font-semibold text-tertiary-placeholder text-2xs';
  }
};

//activity status classes
export const getActivityStatusClasses = (status) => {
  switch (status) {
    case STATUS.completed:
      return 'bg-[#ECFDF3] rounded-md w-fit px-3 py-0.5 font-semibold text-primary-green text-2xs';
    case STATUS.inProgress:
      return 'bg-[#EBEBEB] rounded-md w-fit px-3 py-0.5 font-semibold text-tertiary-placeholder text-2xs';
    default:
      return 'rounded-md w-fit px-3 font-semibold text-tertiary-placeholder text-2xs';
  }
};
