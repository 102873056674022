import { Spin } from 'antd';

export default function Card({
  children,
  header,
  headerClasses,
  className,
  hidden = false,
  loading = false,
}) {
  return header ? (
    <div
      className={
        className
          ? `${className} ${hidden ? 'hidden' : ''} w-full rounded-lg font-segoe-ui`
          : `w-full rounded-lg !bg-secondary-bg px-6 py-5 font-segoe-ui shadow-equal-sides ${hidden ? 'hidden' : ''}`
      }
    >
      <div className={headerClasses}>{header}</div>
      <Spin spinning={loading}>{children}</Spin>
    </div>
  ) : (
    <div
      className={
        className
          ? `${className} ${hidden ? 'hidden' : ''} w-full rounded-lg font-segoe-ui`
          : `w-full rounded-lg !bg-secondary-bg px-6 py-5 font-segoe-ui shadow-equal-sides ${hidden ? 'hidden' : ''}`
      }
    >
      <Spin spinning={loading}>{children}</Spin>
    </div>
  );
}
