import Card from '@common/Card';
import Table from '@common/Table';
import {
  columnRenderer,
  getActivityStatusClasses,
  getFormattedDate,
  getLoggedInUser,
  headerRenderer,
} from '@utils/helpers';
import { Button, message, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getProjectDetails,
  selectProjectSlice,
  uploadBulkActivity,
} from '../redux/slice';
import plusNumIcon from '@assets/icons/input/plus_with_num.svg';
import plusNumActiveIcon from '@assets/icons/input/plus_with_num_active.svg';
import userIcon from '@assets/icons/input/user.svg';
import userActiveIcon from '@assets/icons/input/user_active.svg';
import downloadIcon from '@assets/icons/input/download.svg';
import downloadActiveIcon from '@assets/icons/input/download_active.svg';
import uploadIcon from '@assets/icons/input/upload.svg';
import uploadActiveIcon from '@assets/icons/input/upload_active.svg';
import IconButton from '@common/IconButton';
import { useResponsiveness } from '@hooks/useResponsiveness';
import expandIcon from '@assets/icons/input/arrow_down.svg';
import expandDisabledIcon from '@assets/icons/input/arrow_down_disable.svg';
import shrinkIcon from '@assets/icons/input/arrow_up.svg';
import ActivityList from '../components/ActivityList';
import { apiUrl, getLocalToken } from '@utils/apiClient';
import Upload from '@common/Upload';
import { PARTNER_USER, PERMISSIONS } from '@utils/roleRights';
import { ArrowLeftOutlined } from '@ant-design/icons';

export default function ProjectDetails() {
  const { xs, sm, md, lg, xl, xxl } = useResponsiveness();
  const { role } = getLoggedInUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const {
    projectDetailsLoading,
    projectDetailsData,
    uploadBulkActivityLoading,
  } = useSelector(selectProjectSlice);
  const [downloadingId, setDownloadingId] = useState(null);
  const [file, setFile] = useState([]);

  const [expandedPartner, setExpandedPartner] = useState(null);
  const [uploadId, setUploadId] = useState(null);

  //handle click on expand icon
  const handleExpandClick = (partner) => {
    setExpandedPartner((prev) => (prev?.id === partner.id ? null : partner));
    dispatch(getProjectDetails(projectId));
  };

  //download activity bulk upload excel file
  const handleDownloadActivityForm = async (id) => {
    setDownloadingId(id);
    try {
      const params = new URLSearchParams({ id: String(projectId) });
      const response = await fetch(
        `${apiUrl}/activity/export-activity?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to download activity form...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'activity-form.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download activity form...');
    } finally {
      setDownloadingId(null);
    }
  };

  const columns = [
    {
      title: headerRenderer('Partner Name'),
      dataIndex: 'partner_name',
      key: 'partner_name',
      ellipsis: true,
      width: '10%',
      hidden: role === PARTNER_USER,
      render: (text, record, index) => {
        const isExpanded = expandedPartner?.id === record.id;
        const hasNoActivities = record?.partner?.activities?.length === 0;
        return (
          <div className="flex items-center justify-between w-full gap-2 font-segoe-ui">
            <div className="flex items-center w-3/5 gap-2">
              <p className="overflow-hidden text-xs md:text-sm font-segoe-ui text-ellipsis whitespace-nowrap">
                {text || 'NA'}
              </p>
              <span className="flex items-center px-1 rounded-sm text-2xs text-secondary-bg font-segoe-ui bg-primary-placeholder aspect-square">
                {record?.partner?.activities?.length || 0}
              </span>
            </div>
            <button
              className="flex items-center w-2/5 gap-1"
              onClick={() => handleExpandClick(record)}
              disabled={hasNoActivities}
            >
              <span
                className={`font-semibold ${hasNoActivities ? 'text-primary-placeholder' : 'text-primary-bg'}`}
              >
                {isExpanded ? 'Shrink' : 'Expand'}
              </span>
              <img
                src={
                  hasNoActivities
                    ? expandDisabledIcon
                    : isExpanded
                      ? shrinkIcon
                      : expandIcon
                }
              />
            </button>
          </div>
        );
      },
    },
    {
      title: headerRenderer('Partner Type', true, true),
      dataIndex: 'partner_type',
      key: 'partner_type',
      ellipsis: true,
      width: '8%',
      render: (text, record, index) => {
        if (role === PARTNER_USER) {
          const isExpanded = expandedPartner?.id === record.id;
          const hasNoActivities = record?.partner?.activities?.length === 0;
          return (
            <div className="flex items-center justify-between w-full gap-2 font-segoe-ui">
              <div className="flex items-center w-3/5 gap-2">
                <p className="overflow-hidden text-xs md:text-sm font-segoe-ui text-ellipsis whitespace-nowrap">
                  {text || 'NA'}
                </p>
                <span className="flex items-center px-1 rounded-sm text-2xs text-secondary-bg font-segoe-ui bg-primary-placeholder aspect-square">
                  {record?.partner?.activities?.length || 0}
                </span>
              </div>
              <button
                className="flex items-center w-2/5 gap-1"
                onClick={() => handleExpandClick(record)}
                disabled={hasNoActivities}
              >
                <span
                  className={`font-semibold ${hasNoActivities ? 'text-primary-placeholder' : 'text-primary-bg'}`}
                >
                  {isExpanded ? 'Shrink' : 'Expand'}
                </span>
                <img
                  src={
                    hasNoActivities
                      ? expandDisabledIcon
                      : isExpanded
                        ? shrinkIcon
                        : expandIcon
                  }
                />
              </button>
            </div>
          );
        } else {
          return columnRenderer(text);
        }
      },
    },
    {
      title: headerRenderer('Partner User'),
      dataIndex: 'partner_user',
      key: 'partner_user',
      ellipsis: true,
      width: '10%',
      hidden: role === PARTNER_USER,
      render: (text, record, index) => columnRenderer(text, true, null),
    },
    {
      title: headerRenderer('Start Date'),
      dataIndex: 'start_date',
      key: 'start_date',
      width: '8%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Completion Date', true, true),
      dataIndex: 'completion_date',
      key: 'completion_date',
      width: '8%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Status', true, true),
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(
          text,
          false,
          null,
          getActivityStatusClasses(record.status),
          '-',
        ),
    },
    {
      title: null,
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <Space className="flex items-center justify-center">
          {PERMISSIONS.canCreateActivity(role) && (
            <IconButton
              tooltipName={'Add Activity'}
              src={plusNumIcon}
              srcActive={plusNumActiveIcon}
              onClick={() =>
                navigate('/dashboard/activity?mode=create', {
                  state: { project: projectDetailsData, partner: record },
                })
              }
            />
          )}
          {PERMISSIONS.canCreateBulkActivity(role) && (
            <>
              <IconButton
                loading={downloadingId === record.id}
                tooltipName={'Download'}
                src={downloadIcon}
                srcActive={downloadActiveIcon}
                onClick={() => handleDownloadActivityForm(record.id)}
              />
              {uploadBulkActivityLoading && uploadId === record.id ? (
                <Spin size="small" />
              ) : (
                <Upload
                  files={file}
                  setFiles={setFile}
                  allowedFileTypes={['.xlsx']}
                >
                  <IconButton
                    loading={uploadBulkActivityLoading}
                    tooltipName={'Upload'}
                    src={uploadIcon}
                    srcActive={uploadActiveIcon}
                    onClick={() => setUploadId(record.id)}
                  />
                </Upload>
              )}
            </>
          )}
          {/* <IconButton
            tooltipName={'Add Activity'}
            src={userIcon}
            srcActive={userActiveIcon}
          /> */}
        </Space>
      ),
    },
  ];

  //transforming project partners
  const transformedProjectPartners =
    projectDetailsData?.Projects_Partners_Mappings?.map((partner) => {
      return {
        partner,
        id: partner?.id,
        key: partner?.id,
        project_id: partner.project_id,
        partner_name: partner.partner.partner_name,
        partner_type: partner.PartnerType.type,
        partner_user: partner.contact.contact_name,
        start_date: projectDetailsData?.project_start_date
          ? getFormattedDate(projectDetailsData?.project_start_date)
          : '-',
        completion_date: projectDetailsData?.project_end_date
          ? getFormattedDate(projectDetailsData?.project_end_date)
          : '-',
        status: projectDetailsData?.status,
      };
    });

  //uploading files on file state change
  useEffect(() => {
    const [uploadedFile] = file;
    if (uploadedFile && uploadId) {
      console.log(uploadId, uploadedFile);
      const fd = new FormData();
      fd.append('file', uploadedFile);
      fd.append('partner_user_mapping_id', uploadId);
      dispatch(uploadBulkActivity(fd)).then((res) => {
        if (res?.payload?.status) {
          setFile([]);
          setUploadId(null);
          dispatch(getProjectDetails(projectId));
        }
      });
    }
  }, [file]);

  //fetch project detail by id
  useEffect(() => {
    if (projectId) {
      dispatch(getProjectDetails(projectId));
    }
  }, [projectId]);

  return (
    <Card
      className="my-2 md:my-8"
      headerClasses={
        'font-segoe-ui flex justify-between items-center font-semibold text-2xl text-primary-bg'
      }
      header={
        <>
          <span>{projectDetailsData?.project_name}</span>
          <Button
            icon={<ArrowLeftOutlined />}
            className="font-medium !border-none border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        </>
      }
    >
      <Card
        className={
          'mt-5 w-full hide-footer rounded-lg !bg-secondary-bg font-segoe-ui shadow-equal-sides'
        }
      >
        <Table
          hideScroll={lg || xxl ? true : false}
          columns={columns}
          dataSource={transformedProjectPartners}
          loading={projectDetailsLoading}
          components={{
            body: {
              cell: (props) => (
                <td {...props} className="!px-6 !py-2.5 !border-gray-200" />
              ),
            },
            header: {
              cell: (props) => (
                <th
                  {...props}
                  className="!bg-secondary-bg !py-3 !px-6 !border-gray-200"
                >
                  {props.children}
                </th>
              ),
            },
          }}
          rowClassName={(record, index) =>
            record.id === expandedPartner?.id
              ? 'shadow-md'
              : `hover:!bg-primary-screen`
          }
          expandable={{
            expandedRowRender: (record) => (
              <ActivityList
                activities={record.partner.activities}
                project={projectDetailsData}
                partner={record}
              />
            ),
            rowExpandable: () => true,
            expandIcon: () => null,
            expandedRowKeys: expandedPartner ? [expandedPartner.id] : [],
            showExpandColumn: false,
          }}
        />
      </Card>
    </Card>
  );
}
