import Label from '@common/Label';
import { getFormattedDate } from '@utils/helpers';

export default function ActivityBar({ project, partner }) {

  const labelClasses =
    'text-primary-placeholder text-xs sm:text-sm md:text-base lg:text-md font-segoe-ui';
  const descriptionClasses =
    'text-secondary-bg md:text-base text-xs sm:text-sm lg:text-md font-semibold font-segoe-ui';
  const containerClasses = 'flex items-center gap-2';

  return (
    <div className="flex justify-between p-2 rounded-b-lg md:p-3 xl:p-4 bg-tertiary-placeholder">
      <div className="flex flex-col items-start gap-1 lg:items-center lg:flex-row lg:gap-7">
        <Label
          label={'Project Name:'}
          descriptionClasses={descriptionClasses}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          description={project?.project_name}
        />
        <Label
          label={'Partner Type:'}
          descriptionClasses={descriptionClasses}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          description={partner?.partner_type}
        />
      </div>
      <div className="flex flex-col items-start gap-1 lg:items-center lg:flex-row lg:gap-7">
        <Label
          label={'Start Date:'}
          descriptionClasses={descriptionClasses}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          description={getFormattedDate(project?.project_start_date) || "-"}
        />
        <Label
          label={'Completion Date:'}
          descriptionClasses={descriptionClasses}
          labelClasses={labelClasses}
          containerClasses={containerClasses}
          description={getFormattedDate(project?.project_end_date) || "-"}
        />
      </div>
    </div>
  );
}
