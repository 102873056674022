import FormInput from '@common/FormInput';
import { Button, Col, Form, Row, Space, Spin, Tooltip } from 'antd';
import calenderIcon from '@assets/icons/input/calender.svg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import infoIcon from '@assets/icons/input/info.svg';
import { getPartners, selectMastersSlice } from '@features/masterRedux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  createActivity,
  selectActivitySlice,
  updateActivity,
} from '../redux/slice';
import {
  ACCEPTANCE_STATUS,
  getDateDifference,
  getFormattedDate,
  getLoggedInUser,
  STATUS,
} from '@utils/helpers';
import {
  selectProjectSlice,
  stopActivity,
} from '@features/project/redux/slice';
import ModalCtx from '@common/ModalCtx';
import {
  GRANUAL_USER,
  PARTNER,
  PARTNER_USER,
  PERMISSIONS,
} from '@utils/roleRights';
import moment from 'moment';
import { openNotification } from '@utils/notification';

export default function ActivityDetailsForm({
  project,
  partner,
  activityDetails,
  isEdit,
  isCreate,
  activityId,
  activities,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { role } = getLoggedInUser();
  // extracting search params

  const { partnersLoading, partnersList } = useSelector(selectMastersSlice);
  const { createActvityLoading, updateActivityLoading } =
    useSelector(selectActivitySlice);
  const { stopActivityLoading } = useSelector(selectProjectSlice);
  const [isStopActivityModalOpen, setIsStopActivityModalOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  //checking if all the actities in the project are completed
  const checkProjectActivitiesStatus = () => {
    const isAllActivitiesCompeleted = activities?.every(
      (activity) => activity.completion_status === STATUS.completed,
    );
    return isAllActivitiesCompeleted;
  };

  //handle craete pr update activity
  const handleOnSubmit = (values) => {
    console.log('click');
    if (!partner.id) {
      if (project.id) {
        navigate(`dashboard/project/${project.id}`);
      } else {
        navigate('/');
      }
    } else {
      const payload = {
        remarks_by_granules: values.remarks,
        partner_user_mapping_id: partner.id,
        ...values,
        id: isEdit ? activityId : null,
        status: checkProjectActivitiesStatus(),
      };

      if (isEdit) {
        dispatch(updateActivity(payload)).then((res) => {
          if (res?.payload?.success) {
            navigate(`/dashboard/project/${project.id}`);
          }
        });
      } else {
        dispatch(createActivity(payload)).then((res) => {
          if (res?.payload?.status) {
            navigate(`/dashboard/project/${project.id}`);
          }
        });
      }
    }
  };

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.base_start_date) {
      form.setFieldsValue({ completion_status: STATUS.inProgress });
      if (values.actual_completion_date) {
        form.setFieldsValue({
          estimated_duration: String(
            getDateDifference(
              values.actual_completion_date,
              values.base_start_date,
            ),
          ),
        });
      }

      if (values.base_end_date) {
        form.setFieldsValue({
          overall_duration: String(
            getDateDifference(values.base_end_date, values.base_start_date),
          ),
        });
      }
    }

    if (changedVal.actual_completion_date && values.base_start_date) {
      form.setFieldsValue({
        estimated_duration: String(
          getDateDifference(
            values.actual_completion_date,
            values.base_start_date,
          ),
        ),
      });
    }

    if (changedVal.base_end_date && values.base_start_date) {
      form.setFieldsValue({
        overall_duration: String(
          getDateDifference(values.base_end_date, values.base_start_date),
        ),
      });
    }

    if (isEdit) {
      if (changedVal.actual_completion_date) {
        form.setFieldsValue({ completion_status: STATUS.completed });
      }

      if (changedVal.current_end_date) {
        form.setFieldsValue({ estimated_date: null });
      }

      if (changedVal.completion_status) {
        if (values.completion_status === STATUS.inProgress) {
          form.setFieldsValue({ actual_completion_date: null });
          setIsFormDirty(true);
        } else {
          form.setFieldsValue({
            actual_completion_date:
              activityDetails?.actual_completion_date &&
              moment(activityDetails?.actual_completion_date),
          });
          setIsFormDirty(false);
        }
      }
    }
  };
  
  //transforming partners list
  const transformedPartnersList = partnersList?.map((pa) => {
    return { label: pa.partner_name, value: pa.id };
  });

  //transforming status list
  const transformedStatusList = [
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
  ];

  //activity Accept
  const handleActivityAccept = () => {
    const payload = {
      id: activityId,
      acceptance_status: ACCEPTANCE_STATUS.accept,
      status_type: ACCEPTANCE_STATUS.accept,
      status: STATUS.completed,
    };
    dispatch(updateActivity(payload)).then((res) => {
      if (res?.payload?.success) {
        navigate('/dashboard/project/' + project.id);
      }
    });
  };

  //stopping activity
  const handleStopActivity = (values) => {
    if (isStopActivityModalOpen) {
      const payload = {
        id: activityId,
        acceptance_status: ACCEPTANCE_STATUS.stopActivty,
        status_type: ACCEPTANCE_STATUS.stopActivty,
        remarks_by_granules: values.stop_activity_remarks,
        completion_status: '',
      };
      dispatch(stopActivity(payload)).then((res) => {
        setIsStopActivityModalOpen(false);
        if (res?.payload?.success) {
          navigate('/dashboard/project/' + project.id);
        }
      });
    } else {
      setIsStopActivityModalOpen(true);
    }
  };

  //fetching partners list
  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  //populating form if is edit
  useEffect(() => {
    if (isEdit) {
      form.setFieldsValue({
        activity_desc: activityDetails?.activity_desc,
        responsibility: activityDetails?.responsibility,
        completion_status: activityDetails?.completion_status,
        estimated_duration: activityDetails?.estimated_duration,
        overall_duration: activityDetails?.overall_duration,
        base_end_date:
          activityDetails?.base_end_date &&
          moment(activityDetails?.base_end_date),
        base_start_date:
          activityDetails?.base_start_date &&
          moment(activityDetails?.base_start_date),
        current_end_date:
          activityDetails?.current_end_date &&
          moment(activityDetails?.current_end_date),
        current_start_date:
          activityDetails?.current_start_date &&
          moment(activityDetails?.current_start_date),
        estimated_date:
          activityDetails?.estimated_date &&
          moment(activityDetails?.estimated_date),
        actual_completion_date:
          activityDetails?.actual_completion_date &&
          moment(activityDetails?.actual_completion_date),
        remarks:
          activityDetails?.remarks_by_granules ||
          activityDetails?.remarks_by_partner,
      });
    }
  }, [isEdit, activityDetails]);

  return (
    <Form
      form={form}
      onFinish={handleOnSubmit}
      onValuesChange={handleFormValuesChange}
      layout="vertical"
      className="sm:mx-2 md:mx-4 lg:mx-7"
    >
      <Spin
        spinning={
          partnersLoading ||
          stopActivityLoading ||
          updateActivityLoading ||
          createActvityLoading
        }
      >
        <Row className="mb-10">
          <Col span={24}>
            <FormInput
              name={'activity_desc'}
              label={'Activity Descripiton'}
              autoSize
              placeholder="Enter activity description"
              required
              validateMsg={'Activity description is required'}
              hideColon
              disabled={isEdit}
            />
          </Col>
        </Row>

        {/* 1st row */}
        <div className="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-5">
          <FormInput
            name={'responsibility'}
            label={'Responsibility'}
            placeholder="Select Responsibility"
            type="select"
            options={transformedPartnersList}
            optionRender={(option) => {
              return (
                <span className="text-xs font-segoe-ui text-tertiary-text">
                  {option.label}
                </span>
              );
            }}
            loading={partnersLoading}
            required
            validateMsg={'Responsibility is required'}
            hideColon
            disabled={isEdit}
          />
          <FormInput
            name={'base_start_date'}
            label={'Base Start Date'}
            placeholder="Select base start date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            required
            validateMsg={'Base start is required'}
            hideColon
            disabled={isEdit}
          />
          <FormInput
            name={'base_end_date'}
            label={'Base End Date'}
            placeholder="Select base end date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            required
            validateMsg={'Base end is required'}
            hideColon
            disabled={isEdit}
          />
          <FormInput
            name={'current_start_date'}
            label={'Current Start Date'}
            placeholder="Select current start date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            rules={[
              {
                validator: (_, value) => {
                  const values = form.getFieldsValue();
                  if (values.current_end_date && !value) {
                    return Promise.reject(
                      new Error('Current start date is required'),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            disabled={
              activityDetails?.completion_status === STATUS.completed ||
              (isEdit &&
                activityDetails?.current_start_date &&
                role === GRANUAL_USER) ||
              (isEdit && (role === PARTNER_USER || role === PARTNER))
            }
          />
          <FormInput
            name={'current_end_date'}
            label={'Current End Date'}
            placeholder="Select current end date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            rules={[
              {
                validator: (_, value) => {
                  const values = form.getFieldsValue();
                  if (values.current_start_date && !value) {
                    return Promise.reject(
                      new Error('Current end date is required'),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}
            disabled={
              (isEdit && (role === PARTNER_USER || role === PARTNER)) ||
              activityDetails?.completion_status === STATUS.completed
            }
          />

          {/* 2nd row */}
          <FormInput
            name={'estimated_date'}
            label={'Estimated date'}
            placeholder="Select estimated date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            disabled={
              activityDetails?.completion_status === STATUS.completed ||
              (isCreate && role === GRANUAL_USER) ||
              (isEdit && role === GRANUAL_USER)
            }
            rules={[
              {
                validator: (_, value) => {
                  if (role === PARTNER_USER || role === PARTNER) {
                    const values = form.getFieldsValue([
                      'estimated_date',
                      'actual_completion_date',
                    ]);
                    if (!values.actual_completion_date && !value) {
                      return Promise.reject(
                        new Error('estimated date is required'),
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
                dependencies: ['actual_completion_date'],
              },
            ]}
          />
          <FormInput
            name={'actual_completion_date'}
            label={'Actual Completion Date'}
            placeholder="Select actual completion date"
            type="date"
            allowClear
            suffixIcon={<img src={calenderIcon} />}
            labelEllipsis
            rules={[
              {
                validator: (_, value) => {
                  if (role === PARTNER_USER || role === PARTNER) {
                    const values = form.getFieldsValue([
                      'estimated_date',
                      'actual_completion_date',
                    ]);
                    if (!values.estimated_date && !value) {
                      return Promise.reject(
                        new Error('Actual completion date is required'),
                      );
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
                dependencies: ['estimated_date'],
              },
            ]}
            disabled={
              (isCreate && role === GRANUAL_USER) ||
              (isEdit && role === GRANUAL_USER) ||
              (isEdit && activityDetails?.actual_completion_date)
            }
          />
          <FormInput
            name={'overall_duration'}
            label={
              <div className="flex items-center justify-between gap-2">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  Overall Duration (In Months)
                </span>
                <Tooltip
                  title="Total time taken to complete the activity"
                  placement="top"
                >
                  <img src={infoIcon} className="hover:opacity-60" />
                </Tooltip>
              </div>
            }
            disabled
            labelEllipsisWithoutTooltip
            placeholder="Overall duration"
          />
          <FormInput
            name={'estimated_duration'}
            label={
              <div className="flex items-center justify-between gap-2">
                <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                  Estimated Duration (In Months)
                </span>
                <Tooltip
                  title="Difference of actual agreed base date"
                  placement="top"
                >
                  <img src={infoIcon} className="hover:opacity-60" />
                </Tooltip>
              </div>
            }
            disabled
            labelEllipsisWithoutTooltip
            placeholder="Estimated duration"
          />
          <FormInput
            name={'completion_status'}
            label={'Completion Status'}
            placeholder="Select status"
            type="select"
            optionRender={(option) => {
              return (
                <span className="text-xs font-segoe-ui text-tertiary-text">
                  {option.label}
                </span>
              );
            }}
            options={transformedStatusList}
            disabled={
              isCreate ||
              role === PARTNER ||
              role === PARTNER_USER ||
              (isEdit &&
                activityDetails?.completion_status === STATUS.inProgress &&
                !activityDetails?.actual_completion_date)
            }
          />
        </div>

        <Row className="mt-4">
          <Col span={24}>
            <FormInput
              name={'remarks'}
              label={'Remarks'}
              autoSize
              placeholder="Enter remarks"
              disabled={
                activityDetails?.completion_status === STATUS.completed &&
                (role === PARTNER || role === PARTNER_USER)
              }
            />
          </Col>
        </Row>

        {/* action buttons */}
        <Space size="middle" className="flex items-center justify-center mt-10">
          {/* .................back............ */}
          <Button
            className="!p-4.5 font-medium border border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          {/* .................create and edit............ */}
          <Button
            onClick={() => form.submit()}
            className="!p-4.5 font-medium bg-primary-bg text-secondary-bg hover:border hover:border-primary-bg hover:!bg-transparent hover:!text-primary-bg text-md font-segoe-ui"
            loading={createActvityLoading || updateActivityLoading}
            disabled={
              isEdit &&
              activityDetails?.completion_status === STATUS.completed &&
              !isFormDirty
            }
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>

          {/* .................Accept............ */}
          {isEdit &&
            activityDetails?.completion_status === STATUS.completed &&
            PERMISSIONS.canAcceptAcitivity(role) && (
              <Button
                onClick={() => {
                  openNotification({
                    onApprove: () => handleActivityAccept(),
                    title: 'Accept activity',
                    subTitle: 'Are you sure you want to accept this activity?',
                    approveBtnClasses:
                      'w-full !py-2 bg-primary-green text-secondary-bg hover:!bg-transparent hover:!border-primary-green hover:!border hover:!text-primary-green',
                    okBtnLoading: updateActivityLoading,
                    cancelBtnClasses: 'w-full !py-2',
                    approveText: 'Accept',
                    cancelText: 'Cancel',
                  });
                }}
                className="!p-4.5 font-medium bg-primary-green text-secondary-bg hover:border hover:border-primary-green hover:!bg-transparent hover:!text-primary-green text-md font-segoe-ui"
                disabled={isFormDirty}
              >
                Accept
              </Button>
            )}

          {/* .................stop activity............ */}
          {isEdit &&
            activityDetails?.completion_status === STATUS.inProgress &&
            PERMISSIONS.canStopActivity(role) && (
              <Button
                className="!p-4.5 hover:text-primary-bg hover:border-primary-bg font-medium text-md font-segoe-ui border-primary-red text-primary-red"
                loading={stopActivityLoading}
                onClick={handleStopActivity}
              >
                Stop Activity
              </Button>
            )}

          {/* stop activity remarks popup */}
          <ModalCtx
            onApprove={(values) => handleStopActivity(values)}
            onClose={() => setIsStopActivityModalOpen(false)}
            isOpen={isStopActivityModalOpen}
            fieldName="stop_activity_remarks"
            fieldPlaceholder="Enter remark"
            loading={stopActivityLoading}
            headerTitle="Remarks"
            description={'Remarks by Granules'}
            agreeText="Stop Activity"
            agreeBtnClasses={
              'w-full !py-4.5 bg-primary-red text-secondary-bg hover:!bg-transparent hover:!border-primary-red hover:!border hover:!text-primary-red'
            }
          />
        </Space>
      </Spin>
    </Form>
  );
}
