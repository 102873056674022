import apiClient from '@utils/apiClient';

// create partner
export const projectsByDateRange = (payload) => {
  return apiClient.get(apiClient.Urls.projectsByDateRange, payload, true);
};

// upload scord card
export const scoreCardUpload = (payload) => {
  return apiClient.post(apiClient.Urls.uploadScoreCard, payload, true, 'file');
};
