import { Avatar, Dropdown, Menu, message } from 'antd';
import avatarImg from '@assets/images/avatar.png';
import personIcon from '@assets/icons/input/person.svg';
import logoutIcon from '@assets/icons/input/logout.svg';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'features/login/redux/slice';
import { useNavigate } from 'react-router-dom';
import { getLoggedInUser } from '@utils/helpers';

export default function ProfileDropdown({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = getLoggedInUser()

  //handle logout user
  const handleLogout = () => {
    dispatch(logoutUser());
    message.success('Thanks! You have logged out successfully');
    navigate('/login');
  };

  const items = [
    {
      key: '1',
      label: (
        <Menu.Item
          key="1"
          className="w-full !pb-2 !px-0 border-b border-gray-200 !bg-transparent"
        >
          <div className="flex items-center gap-4">
            <Avatar shape="square" size={40} icon={<img src={avatarImg} />} />
            <div>
              <div className="text-sm font-semibold font-segoe-ui text-tertiary-dark">
                {user?.username}
              </div>
              <div className="text-xs text-tertiary-filter">{user?.email}</div>
            </div>
          </div>
        </Menu.Item>
      ),
    },
    {
      key: '2',
      label: (
        <Menu.Item key="2" className="w-full !py-1.5 !px-0 !bg-transparent">
          <div className="flex items-center gap-4">
            <img src={personIcon} className="w-4" alt="profile" />
            <div>
              <div className="text-xs font-segoe-ui text-tertiary-dark">
                View profile
              </div>
            </div>
          </div>
        </Menu.Item>
      ),
    },
    {
      key: '3',
      label: (
        <Menu.Item key="2" className="w-full !py-1.5 !px-0 !bg-transparent">
          <div className="flex items-center gap-4">
            <img src={personIcon} className="w-4" alt="profile" />
            <div>
              <div className="text-xs font-segoe-ui text-tertiary-dark">
                Settings
              </div>
            </div>
          </div>
        </Menu.Item>
      ),
    },
    {
      key: '4',
      label: (
        <Menu.Item
          key="2"
          className="w-full !py-1.5 !px-0 border-t border-gray-200 !bg-transparent"
          onClick={handleLogout}
        >
          <div className="flex items-center gap-4">
            <img src={logoutIcon} alt="logout" />
            <div>
              <div className="text-xs font-semibold font-segoe-ui text-tertiary-dark">
                Logout
              </div>
            </div>
          </div>
        </Menu.Item>
      ),
    },
  ];

  return (
    <>
      <Dropdown
        placement="bottomLeft"
        overlayClassName="w-64 !p-0 !hover:bg-transparent"
        menu={{ items }}
        arrow={false}
      >
        {children}
      </Dropdown>
    </>
  );
}
