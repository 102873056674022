import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { getPartners, selectMastersSlice } from '@features/masterRedux/slice';
import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PartnerProjListView from './components/PartnerProjListView';

export default function Partner() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const { partnersLoading, partnersList } = useSelector(selectMastersSlice);

  //handle for on submit
  const handleOnSubmit = (values) => {};

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.partner) {
      setSelectedPartnerId(values.partner);
    }
  };

  //transforming partners list
  const transformedPartnersList = partnersList?.map((pa) => {
    return { label: pa.partner_name, value: pa.id };
  });

  //fetching partners list
  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  return (
    <>
      <Card
        className="my-2 md:mt-8 md:mb-4"
        headerClasses={'font-segoe-ui font-semibold text-2xl text-primary-bg'}
        header={'Partners'}
      >
        <Card
          loading={partnersLoading}
          className={
            'mt-5 w-full p-5 rounded-lg !bg-secondary-bg font-segoe-ui shadow-equal-sides'
          }
        >
          <Form
            form={form}
            layout="vertical"
            onValuesChange={handleFormValuesChange}
          >
            <Row>
              <Col span={24} md={{ span: 8 }}>
                <FormInput
                  name={'partner'}
                  type="select"
                  label={'Select Partner Name'}
                  placeholder="Select partner"
                  options={transformedPartnersList}
                  optionRender={(option) => {
                    return (
                      <span className="text-xs font-segoe-ui text-tertiary-text">
                        {option.label}
                      </span>
                    );
                  }}
                  loading={partnersLoading}
                />
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
      {selectedPartnerId && (
        <Card className={'bg-secondary-bg'}>
          <PartnerProjListView
            partnerId={selectedPartnerId}
            partnerName={
              partnersList?.find((partner) => partner.id === selectedPartnerId)
                ?.partner_name || 'NA'
            }
          />
        </Card>
      )}
    </>
  );
}
