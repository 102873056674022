import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { projectList } from './api';

const actions = {
  PROJECT_LIST: 'dashboard/PROJECT_LIST',
};

export const getProjectList = createAsyncThunk(
  actions.PROJECT_LIST,
  async (payload) => {
    const response = await projectList(payload);
    return response;
  },
);

const initialState = {
  projectListLoading: false,
  projectList: [],
  pagination: {},
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // request otp
    builder
      .addCase(getProjectList.pending, (state) => {
        state.projectListLoading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        const { success, data, message: msg, pagination } = action.payload;
        state.projectListLoading = false;

        if (success) {
          state.projectList = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectList.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectListLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectDashboardSlice = (state) => {
  return state.dashboard;
};

export default dashboardSlice.reducer;
