import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { login, requestOtp } from './api';
import LocalStorage from '@utils/localStorage';

const actions = {
  REQUEST_OTP: 'auth/REQUEST_OTP',
  LOGIN: 'auth/LOGIN',
};

export const sendOtpRequest = createAsyncThunk(
  actions.REQUEST_OTP,
  async (payload) => {
    const response = await requestOtp(payload);
    return response;
  },
);

export const loginUser = createAsyncThunk(actions.LOGIN, async (payload) => {
  const response = await login(payload);
  return response;
});

const initialState = {
  requestOtpLoading: false,
  requestOtpData: null,
  loading: false,
  accessToken: null,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Handle logout
    logoutUser: (state) => {
      state.accessToken = '';
      state.user = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    // login user
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        // Access properties directly from action.payload
        const { token, data, message: msg } = action.payload;
        state.loading = false;
        state.accessToken = token;
        state.user = data;

        if (token) {
          const newUser = {
            accessToken: token,
            user_details: data,
          };
          LocalStorage.setItem('granules_Alliance', JSON.stringify(newUser));
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        const errorMsg = action.error.message || 'Login failed!';
        state.loading = false;
        message.error(errorMsg);
      });

    // request otp
    builder
      .addCase(sendOtpRequest.pending, (state) => {
        state.requestOtpLoading = true;
      })
      .addCase(sendOtpRequest.fulfilled, (state, action) => {
        const { success, data, message: msg } = action.payload;
        state.requestOtpLoading = false;

        if (success) {
          message.success(msg);
          state.requestOtpData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(sendOtpRequest.rejected, (state, action) => {
        const errorMsg = action.payload.message || 'Login failed!';
        state.requestOtpLoading = false;
        message.error(errorMsg);
      });
  },
});

export const { logoutUser } = authSlice.actions;

export const selectAuthSlice = (state) => {
  return state.auth;
};

export default authSlice.reducer;
