import { HolderOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { createContext, useContext, useMemo } from 'react';
import Table from './Table';
import { PARTNER, PARTNER_USER, PERMISSIONS } from '@utils/roleRights';
import { getLoggedInUser } from '@utils/helpers';

export default function DraggableRowTable({
  columns,
  dataSource,
  onDragEnd,
  loading,
  isConsolidated
}) {
  const { role } = getLoggedInUser();
  const columnsWithHandler = [
    {
      dataIndex: 'drag_sort',
      key: 'drag_sort',
      width: '2%',
      hidden: !PERMISSIONS.canToggleActivity(role) || isConsolidated,
      render: (text, record, index) => <DragHandle />,
    },
    ...columns,
  ];

  const RowContext = createContext({});
  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <HolderOutlined
        ref={setActivatorNodeRef}
        {...listeners}
        className="text-lg cursor-move text-tertiary-icon"
      />
    );
  };

  const Row = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props['data-row-key'],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: 'relative',
            zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners],
    );
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={dataSource.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          columns={columnsWithHandler}
          dataSource={dataSource}
          components={{
            body: {
              row: Row,
              cell: (props) => {
                return (
                  <td {...props} className="!px-2 !py-2 !border-gray-200" />
                );
              },
            },
            header: {
              cell: (props) => {
                return (
                  <th
                    {...props}
                    className="!py-2 !px-2 !border-gray-200 !bg-[#F9FAFB]"
                  >
                    {props.children}
                  </th>
                );
              },
            },
          }}
          spanWidth={2100}
          loading={loading}
        />
      </SortableContext>
    </DndContext>
  );
}
