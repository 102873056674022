import apiClient from '@utils/apiClient';

// request otp
export const requestOtp = (payload) => {
  return apiClient.post(apiClient.Urls.requestOtp, payload);
};

// login user
export const login = (payload) => {
  return apiClient.post(apiClient.Urls.login, payload);
};
