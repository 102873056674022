import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;

const encodeQueryData = (data) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

export const getLocalToken = () => {
  const user = LocalStorage.getItem('granules_Alliance');
  const authToken = user?.accessToken;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    requestOtp: `${apiUrl}/users/request-otp`,
    login: `${apiUrl}/users/login`,
    projectList: `${apiUrl}/projects/get-projects`,
    createPartner: `${apiUrl}/partner/create_partners`,
    createProject: `${apiUrl}/projects/create-project`,
    projectDetails: `${apiUrl}/projects/get-project-by-id`,
    partnerTypes: `${apiUrl}/partnerTypes/get-partnerType`,
    partners: `${apiUrl}/partner/get_partners`,
    partnerAddresses: `${apiUrl}/partner/get_address_by_partner_id`,
    projectsByPartner: `${apiUrl}/projects/get-projects-by-partner-id`,
    deleteActivity: `${apiUrl}/activity/delete-activity`,
    createActivity: `${apiUrl}/activity/create-activity`,
    uploadBulkActivity: `${apiUrl}/activity/import-activity`,
    activityById: `${apiUrl}/activity/get-activity-by-id`,
    reAssignPartner: `${apiUrl}/projects/reassign-to-partner`,
    shortCloseActivity: `${apiUrl}/projects/short_close`,
    stopActivity: `${apiUrl}/activity/update-activity`,
    updateActivity: `${apiUrl}/activity/update-activity`,
    toggleActivity: `${apiUrl}/activity/toggle-activity`,
    auditTrailsById: `${apiUrl}/activity/get-audit-trails-by-id`,
    auditTrailsByPartnerId: `${apiUrl}/activity/get-audit-trails-by-partner-id`,
    createPartnerUser: `${apiUrl}/partner/create-partner-user`,
    projectsByDateRange: `${apiUrl}/projects/get-projects-by-date`,
    uploadScoreCard: `${apiUrl}/activity/import-scorecard`,
    checkDupProjectName: `${apiUrl}/projects/check-dup-project`,
    updateProject: `${apiUrl}/projects/add-partner-to-existing-project`,
    checkDupPartnerEmail: `${apiUrl}/partner/check-duplicate-partners`,
  },

  make: async (url, method, params, auth = false, type = '') => {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    let authHeaders = {
      Authorization: getLocalToken(),
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders,
      };
    }

    if (method === 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers,
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params),
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: (url, params, auth = false) => {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: (url, params, auth = false, type = '') => {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: (url, params, auth = false) => {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: (url, params, auth = false) => {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: (url, params, auth = false) => {
    return apiClient.make(url, 'DELETE', params, auth);
  },
};

export default apiClient;
