import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  activityById,
  activityCreate,
  activityUpdate,
  auditTrailsById,
  auditTrailsByPartnerId,
  removeActivity,
} from './api';

const actions = {
  CREATE_ACTIVITY: 'activity/CREATE_ACTIVITY',
  UPDATE_ACTIVITY: 'activity/UPDATE_ACTIVITY',
  ACTIVITY_BY_ID: 'activity/ACTIVITY_BY_ID',
  DELETE_ACTIVITY: 'activity/DELETE_ACTIVITY',
  AUDIT_TRAILS_BY_ID: 'project/AUDIT_TRAILS_BY_ID',
  AUDIT_TRAILS_BY_PARTNER_ID: 'project/AUDIT_TRAILS_BY_PARTNER_ID',
};

export const createActivity = createAsyncThunk(
  actions.CREATE_ACTIVITY,
  async (payload) => {
    const response = await activityCreate(payload);
    return response;
  },
);

export const getActivityById = createAsyncThunk(
  actions.ACTIVITY_BY_ID,
  async (payload) => {
    const response = await activityById(payload);
    return response;
  },
);

export const updateActivity = createAsyncThunk(
  actions.UPDATE_ACTIVITY,
  async (payload) => {
    const response = await activityUpdate(payload);
    return response;
  },
);

export const deleteActivity = createAsyncThunk(
  actions.DELETE_ACTIVITY,
  async (payload) => {
    const response = await removeActivity(payload);
    return response;
  },
);

export const getAuditTrailsById = createAsyncThunk(
  actions.AUDIT_TRAILS_BY_ID,
  async (payload) => {
    const response = await auditTrailsById(payload);
    return response;
  },
);

export const getAuditTrailsByPartnerId = createAsyncThunk(
  actions.AUDIT_TRAILS_BY_PARTNER_ID,
  async (payload) => {
    const response = await auditTrailsByPartnerId(payload);
    return response;
  },
);

const initialState = {
  createActvityLoading: false,
  activityDataLoading: false,
  activityData: null,
  updateActivityLoading: false,
  deleteActivityLoading: false,
  auditTrailsLoading: false,
  auditTrailsData: null,
};

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create activity
    builder
      .addCase(createActivity.pending, (state) => {
        state.createActvityLoading = true;
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        const { status, message: msg } = action.payload;
        state.createActvityLoading = false;

        if (status) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createActivity.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.createActvityLoading = false;
        message.error(errorMsg);
      });

    // activity by id
    builder
      .addCase(getActivityById.pending, (state) => {
        state.activityDataLoading = true;
      })
      .addCase(getActivityById.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.activityDataLoading = false;

        if (success) {
          state.activityData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getActivityById.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.activityDataLoading = false;
        message.error(errorMsg);
      });

    // update activity
    builder
      .addCase(updateActivity.pending, (state) => {
        state.updateActivityLoading = true;
      })
      .addCase(updateActivity.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.updateActivityLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(updateActivity.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.updateActivityLoading = false;
        message.error(errorMsg);
      });

    // delete activity
    builder
      .addCase(deleteActivity.pending, (state) => {
        state.deleteActivityLoading = true;
      })
      .addCase(deleteActivity.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.deleteActivityLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteActivity.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.deleteActivityLoading = false;
        message.error(errorMsg);
      });

    // audit trail
    builder
      .addCase(getAuditTrailsById.pending, (state) => {
        state.auditTrailsLoading = true;
      })
      .addCase(getAuditTrailsById.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.auditTrailsLoading = false;

        if (success) {
          state.auditTrailsData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAuditTrailsById.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.auditTrailsLoading = false;
        message.error(errorMsg);
      });

    // audit trail by partner id
    builder
      .addCase(getAuditTrailsByPartnerId.pending, (state) => {
        state.auditTrailsLoading = true;
      })
      .addCase(getAuditTrailsByPartnerId.fulfilled, (state, action) => {
        const { success, message: msg, data } = action.payload;
        state.auditTrailsLoading = false;

        if (success) {
          state.auditTrailsData = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getAuditTrailsByPartnerId.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.auditTrailsLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectActivitySlice = (state) => {
  return state.activity;
};

export default activitySlice.reducer;
