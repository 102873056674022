import Card from '@common/Card';
import ActivityBar from '../components/ActivityBar';
import ActivityDetailsForm from '../components/ActivityDetailsForm';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityById, selectActivitySlice } from '../redux/slice';

export default function CreateActivity() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [mode] = useSearchParams()[0].getAll(['mode']);

  const { activityDataLoading, activityData } =
    useSelector(selectActivitySlice);

  //fetching activity details in edit
  useEffect(() => {
    if (state.activityId && mode === 'edit') {
      dispatch(getActivityById(state.activityId));
    }
  }, []);

  return (
    <Card
      className="my-2 md:my-8 pb-4 w-full rounded-lg !bg-secondary-bg font-segoe-ui shadow-equal-sides"
      header={<ActivityBar project={state?.project} partner={state?.partner} />}
      headerClasses={'px-0 sm:px-2 md:px-4 lg:px-7'}
    >
      <Card
        loading={activityDataLoading}
        className="mx-0 my-2 md:my-5"
        headerClasses={
          'font-segoe-ui sm:mx-2 md:mx-4 lg:mx-7 font-semibold mb-4 text-lg md:text-xl text-primary-bg'
        }
        header={'Create Engagement Details'}
      >
        <ActivityDetailsForm
          project={state?.project}
          partner={state?.partner}
          activityDetails={activityData}
          activityId={state?.activityId}
          isEdit={mode === 'edit'}
          isCreate={mode === 'create'}
          activities={state?.activities}
        />
      </Card>
    </Card>
  );
}
