import { Flex, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Header from './components/Header';
import { Outlet } from 'react-router-dom';

export default function Home() {
  return (
    <Flex className="w-screen h-screen overflow-x-hidden overflow-y-auto">
      {/* <div className="absolute w-full border-2 border-red-500 top-3 sm:border-pink-500 md:border-green-500 lg:border-blue-500 xl:border-purple-500"></div> */}

      <Layout>
        <Header />
        <Content className="bg-primary-screen w-screen px-1 sm:!px-6 md:!px-14 lg:!px-20 xl:!px-28">
          <Outlet />
        </Content>
      </Layout>
    </Flex>
  );
}
