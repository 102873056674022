import React, { useState, useEffect } from 'react';
import moment from 'moment';

export default function CountDown({ endTime, startTime, onExpire }) {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  // Function to calculate initial time left based on startTime and endTime
  function calculateTimeLeft() {
    const duration = moment.duration(endTime.diff(startTime));

    if (duration.asSeconds() <= 0) {
      onExpire(true);
      return { expired: true };
    }

    onExpire(false);
    return {
      minutes: Math.floor(duration.asMinutes() % 60),
      seconds: Math.floor(duration.asSeconds() % 60),
      expired: false,
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const remainingDuration = moment.duration(endTime.diff(moment()));

        if (remainingDuration.asSeconds() <= 0) {
          clearInterval(interval);
          onExpire(true);
          return { expired: true };
        }

        onExpire(false);
        return {
          minutes: Math.floor(remainingDuration.asMinutes() % 60),
          seconds: Math.floor(remainingDuration.asSeconds() % 60),
          expired: false,
        };
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [endTime]);

  if (timeLeft.expired) {
    return (
      <p className="text-xs font-segoe-ui text-tertiary-placeholder">
        OTP Expired
      </p>
    );
  }

  return (
    <div>
      <p className="text-xs font-segoe-ui text-tertiary-placeholder">
        OTP expires in:{' '}
        <span className="text-xs font-semibold font-segoe-ui text-tertiary-text">
          {timeLeft.minutes}m {timeLeft.seconds}s{' '}
        </span>
      </p>
    </div>
  );
}
