import apiClient from '@utils/apiClient';

// create project
export const projectCreate = (payload) => {
  return apiClient.post(apiClient.Urls.createProject, payload, true);
};

// get project details
export const projectDetails = (id) => {
  return apiClient.get(`${apiClient.Urls.projectDetails}/${id}`, null, true);
};

// upload bulk activity
export const bulkActivity = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.uploadBulkActivity}`,
    payload,
    true,
    'file',
  );
};

// reassign partner before short close
export const partnerReAssign = (payload) => {
  return apiClient.post(`${apiClient.Urls.reAssignPartner}`, payload, true);
};

// short close
export const shortClose = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.shortCloseActivity}/${payload.id}`,
    payload,
    true,
  );
};

// stop activity
export const activityStop = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.stopActivity}/${payload.id}`,
    payload,
    true,
  );
};

// toggle activity
export const activityToggle = (payload) => {
  return apiClient.patch(`${apiClient.Urls.toggleActivity}`, payload, true);
};

//update project
export const projectUpdate = (payload) => {
  return apiClient.post(`${apiClient.Urls.updateProject}`, payload, true);
};
