import { StopOutlined } from '@ant-design/icons';
import {
  ACCEPTANCE_STATUS,
  columnRenderer,
  getAcceptanceStatusClasses,
  getAcceptanceStatusLabel,
  getActivityStatusClasses,
  getFormattedDate,
  getLoggedInUser,
  headerRenderer,
  STATUS,
} from '@utils/helpers';
import { Button, Space } from 'antd';
import timelineIcon from '@assets/icons/input/timeline.svg';
import timelineActiveIcon from '@assets/icons/input/timeline_active.svg';
import editIcon from '@assets/icons/input/edit.svg';
import editActiveIcon from '@assets/icons/input/edit_active.svg';
import deleteIcon from '@assets/icons/input/delete.svg';
import deleteActiveIcon from '@assets/icons/input/delete_active.svg';
import IconButton from '@common/IconButton';
import { useEffect, useState } from 'react';
import DraggableRowTable from '@common/DraggableRowTable';
import { arrayMove } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectDetails,
  selectProjectSlice,
  shortClosePartner,
  toggleActivity,
} from '../redux/slice';
import { useNavigate } from 'react-router-dom';
import { openNotification } from '@utils/notification';
import { getProjectsByParterId } from '@features/partner/redux/slice';
import ShortCloseModal from './ShortCloseModal';
import AuditTrails from './AuditTrails';
import {
  deleteActivity,
  getAuditTrailsById,
  getAuditTrailsByPartnerId,
  selectActivitySlice,
} from '@features/activity/redux/slice';
import { PERMISSIONS } from '@utils/roleRights';

export default function ActivityList({
  activities,
  project,
  partner,
  isConsolidated,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role } = getLoggedInUser();
  const [dataSource, setDataSource] = useState([]);
  const { shortClosePartnerLoading, reAssignPartnerLoading } =
    useSelector(selectProjectSlice);
  const { deleteActivityLoading } = useSelector(selectActivitySlice);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [isAuditTrailOpen, setIsAuditTrailOpen] = useState(null);

  //delete activity
  const activityDelete = (id) => {
    dispatch(deleteActivity(id)).then((res) => {
      if (res?.payload?.success) {
        const filteredData = [...dataSource].filter((data) => data.id !== id);
        setDataSource(filteredData);
      }
    });
  };

  //function to move/reposition rows
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id,
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id,
        );
        let payload = null;
        if (activeIndex < overIndex) {
          const disturbedData = dataSource.slice(0, overIndex + 1);
          payload = disturbedData.map((data, i) => {
            if (i === activeIndex) {
              return {
                id: data.id,
                sequence: activeIndex === 0 ? overIndex : overIndex + 1,
              };
            } else {
              return { id: data.id, sequence: i };
            }
          });
        } else {
          const activeItem = dataSource[activeIndex];
          const disturbedData = dataSource.slice(overIndex, activeIndex + 1);
          payload = disturbedData.map((data, i) => {
            if (data.id === activeItem.id) {
              return { id: data.id, sequence: overIndex };
            } else {
              return { id: data.id, sequence: overIndex + i + 1 };
            }
          });
        }
        dispatch(toggleActivity(payload));
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const columns = [
    {
      title: (
        <div className="flex items-center gap-2">
          <label className="text-xs font-semibold font-segoe-ui text-tertiary-placeholder">
            Activity Description
          </label>
          {PERMISSIONS.canReadActivityAuditTrails(role) && (
            <IconButton
              tooltipName={'Complete Activity Timeline'}
              src={timelineIcon}
              srcActive={timelineActiveIcon}
              onClick={() =>
                setIsAuditTrailOpen({ isSingleActivity: false, id: partner.id })
              }
            />
          )}
        </div>
      ),
      dataIndex: 'activity_description',
      key: 'activity_description',
      ellipsis: true,
      width: '15%',
      render: (text, record, index) =>
        columnRenderer(text, true, null, 'text-xs'),
    },
    {
      title: headerRenderer('Responsibility'),
      dataIndex: 'responsibility',
      key: 'responsibility',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Base Start Date'),
      dataIndex: 'base_start_date',
      key: 'base_start_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Base End Date'),
      dataIndex: 'base_end_date',
      key: 'base_end_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Current Start Date'),
      dataIndex: 'current_start_date',
      key: 'current_start_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Current End Date'),
      dataIndex: 'current_end_date',
      key: 'current_end_date',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Estimated Date'),
      dataIndex: 'estimated_date',
      key: 'estimated_date',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Actual Completion Date'),
      dataIndex: 'actual_completion_date',
      key: 'actual_completion_date',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Overall Duration'),
      dataIndex: 'overall_duration',
      key: 'overall_duration',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Estimated Duration'),
      dataIndex: 'estimated_duration',
      key: 'estimated_duration',
      width: '10%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Status'),
      dataIndex: 'status',
      key: 'status',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(
          text,
          false,
          null,
          getActivityStatusClasses(record.status),
          '-',
        ),
    },
    {
      title: headerRenderer('Acceptance Status'),
      dataIndex: 'acceptance_status',
      key: 'acceptance_status',
      width: '8%',
      render: (text, record, index) =>
        columnRenderer(
          getAcceptanceStatusLabel(text),
          false,
          null,
          getAcceptanceStatusClasses(record.acceptance_status),
        ),
    },
    {
      title: headerRenderer('Remarks'),
      dataIndex: 'remarks',
      key: 'remarks',
      width: '10%',
      render: (text, record, index) => columnRenderer(text, true),
    },
    {
      title: null,
      dataIndex: 'action',
      key: 'action',
      width: '8%',
      render: (_, record) => (
        <Space size="middle">
          {!isConsolidated && (
            <>
              <IconButton
                tooltipName={'Edit'}
                src={editIcon}
                srcActive={editActiveIcon}
                disabled={
                  record.acceptance_status === ACCEPTANCE_STATUS.accept ||
                  record.acceptance_status === ACCEPTANCE_STATUS.stopActivty
                }
                onClick={() =>
                  navigate('/dashboard/activity?mode=edit', {
                    state: {
                      activityId: record.id,
                      project,
                      partner,
                      activities: dataSource,
                    },
                  })
                }
              />
              {PERMISSIONS.canDeleteActivity(role) && (
                <IconButton
                  tooltipName={'Delete'}
                  src={deleteIcon}
                  srcActive={deleteActiveIcon}
                  disabled={
                    record.acceptance_status === ACCEPTANCE_STATUS.accept ||
                    record.acceptance_status === ACCEPTANCE_STATUS.stopActivty
                  }
                  onClick={() => {
                    if (record.id) {
                      openNotification({
                        onApprove: () => activityDelete(record.id),
                        title: 'Delete activity',
                        subTitle: 'Do you want to delete the activity',
                        approveBtnClasses:
                          'w-full !py-2 bg-primary-red text-secondary-bg hover:!bg-transparent hover:!border-primary-red hover:!border hover:!text-primary-red',
                        okBtnLoading: deleteActivityLoading,
                        cancelBtnClasses: 'w-full !py-2',
                        approveText: 'Yes, delete',
                        cancelText: 'Cancel',
                      });
                    }
                  }}
                />
              )}
            </>
          )}
          {PERMISSIONS.canReadActivityAuditTrails(role) && (
            <IconButton
              tooltipName={'Activity Timeline'}
              src={timelineIcon}
              srcActive={timelineActiveIcon}
              onClick={() =>
                setIsAuditTrailOpen({ isSingleActivity: true, id: record.id })
              }
            />
          )}
        </Space>
      ),
    },
  ];

  //transforming activities
  const transformedActivities = activities?.map((activity) => {
    return {
      id: activity?.id,
      key: activity?.id,
      responsibility: activity.responsibility || 'NA',
      activity_description: activity.activity_desc || 'NA',
      base_start_date: activity.base_start_date
        ? getFormattedDate(activity.base_start_date)
        : '-',
      base_end_date: activity.base_end_date
        ? getFormattedDate(activity.base_end_date)
        : '-',
      current_start_date: activity.current_start_date
        ? getFormattedDate(activity.current_start_date)
        : '-',
      current_end_date: activity.current_end_date
        ? getFormattedDate(activity.current_end_date)
        : '-',
      estimated_date: activity.estimated_date
        ? getFormattedDate(activity.estimated_date)
        : '-',
      actual_completion_date: activity.actual_completion_date
        ? getFormattedDate(activity.actual_completion_date)
        : '-',
      overall_duration: activity.overall_duration
        ? getFormattedDate(activity.overall_duration)
        : '-',
      estimated_duration: activity.estimated_duration
        ? getFormattedDate(activity.estimated_duration)
        : '-',
      status: activity.completion_status,
      acceptance_status: activity.acceptance_status,
      remarks:
        activity.remarks_by_granules || activity.remarks_by_partner || 'NA',
      sequence: activity.sequence,
    };
  });

  //handling short close
  const handleShortClose = () => {
    const payload = {
      id: partner.id,
    };
    dispatch(shortClosePartner(payload)).then((res) => {
      if (res?.payload?.status) {
        if (project.id) {
          dispatch(getProjectDetails(project.id));
        }
      }
    });
  };

  //setting activities in state
  useEffect(() => {
    setDataSource(transformedActivities);
  }, [activities]);

  // fetching audit trails
  useEffect(() => {
    if (isAuditTrailOpen?.id) {
      if (isAuditTrailOpen?.isSingleActivity) {
        dispatch(getAuditTrailsById({ id: isAuditTrailOpen?.id }));
      } else {
        dispatch(getAuditTrailsByPartnerId({ id: isAuditTrailOpen?.id }));
      }
    }
  }, [isAuditTrailOpen?.id]);

  return (
    <div className="flex flex-col w-full">
      <div>
        {!isConsolidated &&
          activities.length &&
          PERMISSIONS.canShortClosePartner(role) && (
            <Button
              type="link"
              icon={<StopOutlined />}
              className="text-[#F43E3E] float-end !font-segoe-ui !text-xs font-semibold"
              onClick={() => {
                openNotification({
                  onApprove: () => setIsReassignModalOpen(true),
                  title: 'Short Close Partner',
                  subTitle: 'Do you want to short close the existing partner?',
                  approveBtnClasses:
                    'w-full !py-2 bg-primary-red text-secondary-bg hover:!bg-transparent hover:!border-primary-red hover:!border hover:!text-primary-red',
                  okBtnLoading: shortClosePartnerLoading,
                  cancelBtnClasses: 'w-full !py-2',
                  approveText: 'Yes',
                  cancelText: 'Cancel',
                });
              }}
            >
              Short Close
            </Button>
          )}

        {/* //short close modal */}
        <ShortCloseModal
          project={project}
          partner={partner}
          isOpen={isReassignModalOpen}
          onClose={() => setIsReassignModalOpen(false)}
          onReassign={handleShortClose}
          loading={reAssignPartnerLoading}
        />
      </div>
      <div className="border-2 border-gray-200 rounded-lg">
        <DraggableRowTable
          columns={columns}
          dataSource={dataSource}
          onDragEnd={onDragEnd}
          loading={deleteActivityLoading}
          isConsolidated={isConsolidated}
        />
      </div>

      {/* //audit trail modal */}
      <AuditTrails
        isOpen={isAuditTrailOpen}
        onClose={() => setIsAuditTrailOpen(null)}
        activityList={dataSource}
        partnerName={partner?.partner_name || project?.project_name}
      />
    </div>
  );
}
