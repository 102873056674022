import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { projectsByDateRange, scoreCardUpload } from './api';

const actions = {
  PROJECTS_BY_DATE_RANGE: 'partnerScore/PROJECTS_BY_DATE_RANGE',
  UPLOAD_SCORE_CARD: 'partnerScore/UPLOAD_SCORE_CARD',
};

export const getProjectsByDateRange = createAsyncThunk(
  actions.PROJECTS_BY_DATE_RANGE,
  async (payload) => {
    const response = await projectsByDateRange(payload);
    return response;
  },
);

export const uploadScoreCard = createAsyncThunk(
  actions.UPLOAD_SCORE_CARD,
  async (payload) => {
    const response = await scoreCardUpload(payload);
    return response;
  },
);

const initialState = {
  projectsByDateRange: [],
  projectsByDateRangeLoading: false,
  scoreCardUploadLoading: false,
};

export const partnerScoreSlice = createSlice({
  name: 'partnerScore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create partner
    builder
      .addCase(getProjectsByDateRange.pending, (state) => {
        state.projectsByDateRangeLoading = true;
      })
      .addCase(getProjectsByDateRange.fulfilled, (state, action) => {
        const { status, message: msg, projects } = action.payload;
        state.projectsByDateRangeLoading = false;

        if (status) {
          message.success(msg);
          state.projectsByDateRange = projects;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectsByDateRange.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectsByDateRangeLoading = false;
        message.error(errorMsg);
      });

    // upload score card
    builder
      .addCase(uploadScoreCard.pending, (state) => {
        state.scoreCardUploadLoading = true;
      })
      .addCase(uploadScoreCard.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.scoreCardUploadLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(uploadScoreCard.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.scoreCardUploadLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectPartnerScoreSlice = (state) => {
  return state.partnerScore;
};

export default partnerScoreSlice.reducer;
