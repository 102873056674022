import Modal from '@common/Modal';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AuditTrailsTable from './AuditTrailsTable';
import { selectActivitySlice } from '@features/activity/redux/slice';

export default function AuditTrails({ isOpen, onClose, activityList, partnerName }) {
  const dispatch = useDispatch();
  const { auditTrailsLoading, auditTrailsData } =
    useSelector(selectActivitySlice);

  // transforming activity trail
  // const trails = isOpen?.isSingleActivity
  //   ? auditTrailsData?.map((trail) => {
  //       return {
  //         key: trail.id,
  //         label:
  //           activityList.find((act) => act.id === isOpen?.id)
  //             ?.activity_description || 'NA',
  //         chidren: 'dsds',
  //       };
  //     })
  //   : [];

  return (
    <Modal
      footerComponent={' '}
      btnLoading={auditTrailsLoading}
      headerTitle="Audit Trail"
      isOpen={Boolean(isOpen?.id)}
      onClose={onClose}
      spanWidth={'!w-[90vw] md:!w-[80vw] lg:!w-[75vw]'}
      centered
    >
      {isOpen?.isSingleActivity ? (
        <Collapse
          className="!bg-[#F9FAFB] !font-segoe-ui !text-md"
          expandIconPosition="end"
          items={[
            {
              key: "1",
              label: activityList.find((act) => act.id === isOpen?.id)
                ?.activity_description || "NA",
              children: (
                <AuditTrailsTable
                  data={auditTrailsData}
                  loading={auditTrailsLoading}
                />
              ),
              classNames: {
                body: 'border border-gray-200 !p-0 hide-footer',
                header: '!p-3',
              },
            },
          ]}
          bordered={false}
          defaultActiveKey={['1']}
        />
      ) : (
        <Collapse
          className="!bg-[#F9FAFB] !font-segoe-ui !text-md"
          expandIconPosition="end"
          items={[
            {
              key: "1",
              label: partnerName || "NA",
              children: (
                <AuditTrailsTable
                  data={auditTrailsData}
                  loading={auditTrailsLoading}
                />
              ),
              classNames: {
                body: 'border border-gray-200 !p-0 hide-footer',
                header: '!p-3',
              },
            },
          ]}
          bordered={false}
          defaultActiveKey={['1']}
        />
      )}
    </Modal>
  );
}
