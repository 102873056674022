import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { Button, Col, Form, Row, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import PartnerDetails from '../components/PartnerDetails';
import CheckboxGroup from '@common/CheckboxGroup';
import trashcanIcon from '@assets/icons/input/trashcan.svg';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkDupProjectName,
  getPartners,
  getPartnerTypes,
  selectMastersSlice,
} from '@features/masterRedux/slice';
import {
  createProject,
  getProjectDetails,
  selectProjectSlice,
  updateProject,
} from '../redux/slice';
import debounce from 'lodash/debounce';

export default function CreateProject() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    partnerTypesLoading,
    partnerTypesList,
    checkDupProjectLoading,
    projectAlreadyExist,
  } = useSelector(selectMastersSlice);
  const { createProjectLoading, projectDetailsLoading } =
    useSelector(selectProjectSlice);
  const [mode] = useSearchParams()[0].getAll(['mode']);
  const { projectId } = useParams();

  // partner details
  const [partners, setPartners] = useState([
    {
      partner_id: null,
      partner_users: null,
      partner_address_id: null,
    },
  ]);
  const [selectedPartner, setSelectedPartner] = useState(0);

  //partner types
  const [partnerTypes, setPartnerTypes] = useState(null);
  const [checkedPartnerTypes, setCheckedPartnerTypes] = useState([]);
  const [lastUnCheckedPartnerType, setLastUnCheckedPartnerType] =
    useState(null);
  const [activePartnerType, setActivePartnerType] = useState(null);
  const [currentSelectedPartnerUsers, setCurrentSelectedPartnerUsers] =
    useState([]);
  const [isPartnerNameFilled, setIsPartnerNameFilled] = useState(false);
  const [isPartnerAddressFillded, setIsPartnerAddressFillded] = useState(false);
  const [isPartnerUserNameFillded, setIsPartnerUserNameFillded] =
    useState(false);

  // adding new address tab for the partner
  const handleAddMorePartner = () => {
    setPartners([
      ...partners,
      {
        partner_id: null,
        partner_users: null,
        partner_address_id: null,
      },
    ]);
    setSelectedPartner(partners.length);
  };

  //tranformed options for partner types
  const transformedPartnerTypes = partnerTypesList.map((type) => {
    return { label: type.type, value: type.id };
  });

  //handle craete project form finish
  const handleOnSubmit = (values) => {
    const payload = { ...values, partner_types: partnerTypes };
    if (mode === 'edit') {
      console.log('payload::', payload);
      dispatch(updateProject({ ...payload, project_id: projectId })).then(
        (res) => {
          if (res?.payload?.status) {
            navigate('/', { replace: true });
          }
        },
      );
    } else {
      dispatch(createProject(payload)).then((res) => {
        if (res?.payload?.status) {
          navigate('/', { replace: true });
        }
      });
    }
  };

  //debouncing the prokect name
  const debouncedCheckProjectName = debounce((project_name) => {
    dispatch(checkDupProjectName({ project_name }));
  }, 500);

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.project_name) {
      debouncedCheckProjectName(values.project_name);
    }
  };

  // updating partner types state on partner types checked change
  useEffect(() => {
    if (!activePartnerType) return;
    const isPartnerTypeExist = partnerTypes?.some(
      (type) => type.type_id === activePartnerType,
    );
    if (isPartnerTypeExist) return;

    if (partnerTypes) {
      setPartnerTypes([
        ...partnerTypes,
        { type_id: activePartnerType, partners: null },
      ]);
      setCurrentSelectedPartnerUsers([]);
      setIsPartnerAddressFillded(false);
      setIsPartnerNameFilled(false);
      setIsPartnerUserNameFillded(false);
    } else {
      setPartnerTypes([{ type_id: activePartnerType, partners: null }]);
      setCurrentSelectedPartnerUsers([]);
      setIsPartnerAddressFillded(false);
      setIsPartnerNameFilled(false);
      setIsPartnerUserNameFillded(false);
    }
  }, [checkedPartnerTypes, activePartnerType]);

  //removing a type of partner types if unselected
  useEffect(() => {
    if (partnerTypes && lastUnCheckedPartnerType) {
      const filteredPartnerTypes = [...partnerTypes].filter(
        (type) => type.type_id !== lastUnCheckedPartnerType,
      );
      setPartnerTypes(filteredPartnerTypes);
    }
  }, [lastUnCheckedPartnerType]);

  //updating partner types on partner array change
  useEffect(() => {
    if (partnerTypes && activePartnerType) {
      setPartnerTypes((prevTypes) => {
        return prevTypes.map((type) =>
          type.type_id === activePartnerType ? { ...type, partners } : type,
        );
      });
    }
  }, [partners]);

  //fetching required masters
  useEffect(() => {
    dispatch(getPartnerTypes());
    dispatch(getPartners());
  }, []);

  // fetching project details if mode = edit
  useEffect(() => {
    if (mode === 'edit' && projectId) {
      dispatch(getProjectDetails(projectId)).then((res) => {
        if (res?.payload?.success) {
          const data = res.payload?.data;
          form.setFieldsValue({
            project_name: data.project_name,
            project_desc: data.project_desc,
          });

          // structuting the incoming data as per ui
          const structuredPartners = [];
          if (data?.Projects_Partners_Mappings) {
            for (const partner of data.Projects_Partners_Mappings) {
              const typeExist = structuredPartners.find(
                (part) => part.type_id === partner.partner_type_id,
              );
              if (typeExist) {
                const foundPartner = typeExist.partners.find(
                  (p) =>
                    p.partner_id === partner.partner_id &&
                    p.partner_address_id === partner.contact.address_id,
                );

                if (foundPartner) {
                  const partnerUserExist = foundPartner.partner_users.find(
                    (user) => user.partner_user_id === partner.partner_user_id,
                  );
                  if (partnerUserExist) return;
                  foundPartner.partner_users.push({
                    partner_user_id: partner.partner_user_id,
                    is_active: true,
                  });
                } else {
                  typeExist.partners.push({
                    partner_id: partner.partner_id,
                    partner_address_id: partner.contact.address_id,
                    partner: partner.partner,
                    partner_users: [
                      {
                        partner_user_id: partner.partner_user_id,
                        is_active: true,
                      },
                    ],
                  });
                }
              } else {
                structuredPartners.push({
                  type_id: partner.partner_type_id,
                  partners: [
                    {
                      partner_id: partner.partner_id,
                      partner_address_id: partner.contact.address_id,
                      partner: partner.partner,
                      partner_users: [
                        {
                          partner_user_id: partner.partner_user_id,
                          is_active: true,
                        },
                      ],
                    },
                  ],
                });
              }
            }
          }

          //after structuring storing it in state
          setPartnerTypes(structuredPartners);
          const mappedPartnerTypes = structuredPartners.map(
            (type) => type.type_id,
          );
          setCheckedPartnerTypes(mappedPartnerTypes);
          setActivePartnerType(Math.min(...mappedPartnerTypes));
        }
      });
    }
  }, [projectId]);

  return (
    <Card
      className="my-2 md:my-8"
      headerClasses={'font-segoe-ui font-semibold text-2xl text-primary-bg'}
      header={'Create Project'}
    >
      <Card
        loading={createProjectLoading || projectDetailsLoading}
        className={
          'mt-5 w-full rounded-lg !bg-secondary-bg p-7 font-segoe-ui shadow-equal-sides'
        }
      >
        <Form
          form={form}
          layout="vertical"
          id="create-project-form"
          onFinish={handleOnSubmit}
          onValuesChange={handleFormValuesChange}
          className="flex flex-col gap-5"
        >
          <Row gutter={24}>
            <Col span={24}>
              <FormInput
                label={
                  <Space>
                    <span>Project Name</span>
                    {checkDupProjectLoading && (
                      <LoadingOutlined spin={checkDupProjectLoading} />
                    )}
                  </Space>
                }
                name={'project_name'}
                placeholder="Enter project name"
                disabled={mode === 'edit'}
                required
                validateMsg={'Project name is required'}
              />
              {projectAlreadyExist && (
                <span className="text-xs font-segoe-ui text-primary-red">
                  Project with similar name already exist, please choose
                  different project name.
                </span>
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <FormInput
                label={'Project Description'}
                name={'project_desc'}
                placeholder="Enter project description"
                type="textarea"
                autoSize
                disabled={mode === 'edit'}
              />
            </Col>
          </Row>

          {/* add partner form */}
          <Card
            loading={partnerTypesLoading}
            header={'Add Partner'}
            headerClasses={'font-segoe-ui mb-2 text-sm text-tertiary-text'}
            className={'my-2'}
          >
            {/* partner types */}
            <div>
              <CheckboxGroup
                options={transformedPartnerTypes}
                setActive={setActivePartnerType}
                active={activePartnerType}
                checked={checkedPartnerTypes}
                setChecked={setCheckedPartnerTypes}
                setLastUnChecked={setLastUnCheckedPartnerType}
                lastUnChecked={lastUnCheckedPartnerType}
              />
            </div>

            {/* partner details */}
            <Card
              hidden={!activePartnerType}
              className={'p-4 shadow-equal-sides-dark !rounded-tl-none'}
            >
              <div className="flex w-full overflow-x-auto scrollbar-1">
                {[...partners, { isBtn: true }].map((partner, i) => {
                  return partner?.isBtn ? (
                    <Button
                      icon={<PlusOutlined />}
                      className="text-primary-bg font-semibold text-sm font-segoe-ui !border-none hover:!text-secondary-bg hover:!bg-primary-bg"
                      onClick={handleAddMorePartner}
                    >
                      Add More
                    </Button>
                  ) : (
                    <div
                      key={i}
                      className={`${
                        i === selectedPartner
                          ? 'bg-tertiary-bg cursor-not-allowed'
                          : 'bg-transparent hover:text-primary-bg'
                      } py-2 px-4 rounded-t-lg shrink-0 cursor-pointer font-segoe-ui text-xs md:text-sm font-semibold text-tertiary-text select-none flex items-center gap-2`}
                      onClick={() => setSelectedPartner(i)}
                    >
                      {/* partner name */}
                      <span>
                        {mode === 'edit' && partner.partner
                          ? partner.partner?.partner_name
                          : partners.length > 1
                            ? `Partner ${(i + 1).toString().padStart(2, '0')}`
                            : 'Partner Details'}
                      </span>
                      {/* partner delete */}
                      {partners.length > 1 && selectedPartner !== i && (
                        <img
                          src={trashcanIcon}
                          className="hover:opacity-50"
                          onClick={(e) => {
                            e.stopPropagation();
                            const filteredPartnerList = [...partners].filter(
                              (partner) => partners.indexOf(partner) !== i,
                            );
                            setPartners(filteredPartnerList);
                            if (selectedPartner === 0) return;
                            setSelectedPartner(selectedPartner - 1);
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              {/* partner details form */}
              <div className="bg-tertiary-bg rounded-b-lg p-2.5">
                <PartnerDetails
                  partners={partners}
                  setPartners={setPartners}
                  selectedPartner={selectedPartner}
                  setPartnerTypes={setPartnerTypes}
                  partnerTypes={partnerTypes}
                  activePartnerType={activePartnerType}
                  setSelectedPartner={setSelectedPartner}
                  currentSelectedPartnerUsers={currentSelectedPartnerUsers}
                  setCurrentSelectedPartnerUsers={
                    setCurrentSelectedPartnerUsers
                  }
                  setIsPartnerAddressFillded={setIsPartnerAddressFillded}
                  isPartnerAddressFillded={isPartnerAddressFillded}
                  setIsPartnerNameFilled={setIsPartnerNameFilled}
                  isPartnerNameFilled={isPartnerNameFilled}
                  isPartnerUserNameFillded={isPartnerUserNameFillded}
                  setIsPartnerUserNameFillded={setIsPartnerUserNameFillded}
                  isEdit={mode === 'edit'}
                />
              </div>
            </Card>
          </Card>

          {/* action buttons */}
          <Space size="middle" className="flex items-center justify-center">
            <Button
              className="!p-4.5 font-medium border border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              onClick={() => form.submit()}
              className="!p-4.5 font-medium bg-primary-bg text-secondary-bg hover:border hover:border-primary-bg hover:!bg-transparent hover:!text-primary-bg text-md font-segoe-ui"
              loading={createProjectLoading}
            >
              {mode === 'edit' ? 'Save' : 'Create'}
            </Button>
          </Space>
        </Form>
      </Card>
    </Card>
  );
}
