import Table from '@common/Table';
import { useResponsiveness } from '@hooks/useResponsiveness';
import {
  columnRenderer,
  getFormattedDate,
  headerRenderer,
} from '@utils/helpers';

export default function AuditTrailsTable({ data, loading }) {
  const { xl, xxl } = useResponsiveness();

  const columns = [
    {
      title: headerRenderer('Base Start Date'),
      dataIndex: 'base_start_date',
      key: 'base_start_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Base End Date'),
      dataIndex: 'base_end_date',
      key: 'base_end_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Current Start Date', true, true),
      dataIndex: 'current_start_date',
      key: 'current_start_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Current End Date', true, true),
      dataIndex: 'current_end_date',
      key: 'current_end_date',
      ellipsis: true,
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Estimated Date'),
      dataIndex: 'estimated_date',
      key: 'estimated_date',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Actual Completion Date', true, true),
      dataIndex: 'actual_completion_date',
      key: 'actual_completion_date',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
    {
      title: headerRenderer('Overall Duration'),
      dataIndex: 'overall_duration',
      key: 'overall_duration',
      width: '14%',
      render: (text, record, index) =>
        columnRenderer(text, false, null, 'text-xs'),
    },
  ];

  //transforming activities
  const transformedactivities = data?.map((trail) => {
    return {
      id: trail?.id,
      key: trail?.id,
      base_start_date: trail.base_start_date
        ? getFormattedDate(trail.base_start_date)
        : '-',
      base_end_date: trail.base_end_date
        ? getFormattedDate(trail.base_end_date)
        : '-',
      current_start_date: trail.current_start_date
        ? getFormattedDate(trail.current_start_date)
        : '-',
      current_end_date: trail.current_end_date
        ? getFormattedDate(trail.current_end_date)
        : '-',
      estimated_date: trail.estimated_date
        ? getFormattedDate(trail.estimated_date)
        : '-',
      actual_completion_date: trail.actual_completion_date
        ? getFormattedDate(trail.actual_completion_date)
        : '-',
      overall_duration: trail.overall_duration
        ? getFormattedDate(trail.overall_duration)
        : '-',
    };
  });

  return (
    <Table
      columns={columns}
      loading={loading}
      hideScroll={xl || xxl ? true : false}
      rowClassName={(record, index) => `hover:!bg-primary-screen`}
      components={{
        body: {
          cell: (props) => (
            <td {...props} className="!px-3 !py-2 !border-gray-200" />
          ),
        },
        header: {
          cell: (props) => (
            <th
              {...props}
              className="!bg-secondary-bg !py-2 !px-3 !border-gray-200"
            >
              {props.children}
            </th>
          ),
        },
      }}
      dataSource={transformedactivities}
    />
  );
}
