import { Flex } from 'antd';
import { NAVLINKS as Links } from './links.js';
import { NavLink, useLocation } from 'react-router-dom';
import { getLoggedInUser } from '@utils/helpers.js';

export default function Navlinks() {
  const location = useLocation();
  const { role } = getLoggedInUser();

  const handleClick = (e, isDisabled) => {
    if (isDisabled) {
      e.preventDefault();
    }
  };
  return (
    <Flex className="gap-0 sm:gap-2 md:gap-4">
      {Links.filter((link) => link.access?.includes(role)).map((link) => {
        return (
          <NavLink
            key={link.id}
            onClick={(e) => {
              handleClick(e, link.isDisabled);
            }}
            to={link.path}
            className="!relative"
          >
            {({ isActive }) => {
              const active =
                isActive ||
                link.extraPaths.some((p) => p === location.pathname);
              return (
                <span
                  className={`font-segoe-ui py-2 px-3 font-semibold text-md ${active ? 'text-primary-bg bg-secondary-screen border-b-[3px] border-primary-bg' : 'text-tertiary-text'}`}
                >
                  {link.label}
                </span>
              );
            }}
          </NavLink>
        );
      })}
    </Flex>
  );
}
