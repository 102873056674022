import apiClient from '@utils/apiClient';

// partner types
export const partnerTypes = (payload) => {
  return apiClient.get(apiClient.Urls.partnerTypes, payload, true);
};

// get partners
export const partners = (payload) => {
  return apiClient.get(apiClient.Urls.partners, payload, true);
};

// get partner addresses by partner id
export const partnerAddresses = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.partnerAddresses}/${payload}`,
    payload,
    true,
  );
};

//check duplicate project name
export const checkDupProject = (payload) => {
  return apiClient.get(`${apiClient.Urls.checkDupProjectName}`, payload, true);
};

export const checkDupPartner = (payload) => {
  return apiClient.get(`${apiClient.Urls.checkDupPartnerEmail}`, payload, true);
};
