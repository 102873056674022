import { Flex, Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import SearchBar from '@common/SearchBar';
import Table from '@common/Table';
import IconButton from '@common/IconButton';
import editIcon from '@assets/icons/input/edit.svg';
import editActiveIcon from '@assets/icons/input/edit_active.svg';
import personPlusIcon from '@assets/icons/input/person_plus.svg';
import personPlusActiveIcon from '@assets/icons/input/person_plus_active.svg';
import {
  columnRenderer,
  getLoggedInUser,
  headerRenderer,
  PER_PAGE_ITEM,
} from '@utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProjectList, selectDashboardSlice } from '../redux/slice';
import moment from 'moment';
import { PERMISSIONS } from '@utils/roleRights';

export default function ListView() {
  const { role } = getLoggedInUser();
  const { projectListLoading, projectList, pagination } =
    useSelector(selectDashboardSlice);

  //projectlist state
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns = [
    {
      title: headerRenderer('Project Name'),
      dataIndex: 'project_name',
      key: 'project_name',
      width: '10%',
      sorter: (a, b) => a.project_name?.localeCompare(b.project_name),
      render: (text, record, index) => columnRenderer(text, false, index === 0),
    },
    {
      title: headerRenderer('Project Id'),
      dataIndex: 'project_id',
      key: 'project_id',
      width: '10%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Partner Name'),
      dataIndex: 'partner_name',
      key: 'partner_name',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) => columnRenderer(text, true, null),
    },
    {
      title: headerRenderer('Partner Type'),
      dataIndex: 'partner_type',
      key: 'partner_type',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) => columnRenderer(text, true, null),
    },
    {
      title: headerRenderer('Partner Code'),
      dataIndex: 'partner_code',
      key: 'partner_code',
      ellipsis: true,
      width: '10%',
      render: (text, record, index) => columnRenderer(text, true, null),
    },
    {
      title: headerRenderer('Project Base Start Date', true, true),
      dataIndex: 'start_date',
      key: 'start_date',
      width: '10%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Project Base End Date', true, true),
      dataIndex: 'end_date',
      key: 'end_date',
      width: '10%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: headerRenderer('Project Actual End Date', true, true),
      dataIndex: 'actual_end_date',
      key: 'actual_end_date',
      width: '10%',
      render: (text, record, index) => columnRenderer(text, false, null),
    },
    {
      title: null,
      dataIndex: 'action',
      key: 'action',
      width: '6%',
      onCell: (record) => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (_, record) => (
        <Space>
          {PERMISSIONS.canEditProject(role) && (
            <IconButton
              onClick={() => navigate(`/project/${record.id}?mode=edit`)}
              tooltipName={'Edit'}
              src={editIcon}
              srcActive={editActiveIcon}
            />
          )}
          <IconButton
            tooltipName={'Re-assign'}
            src={personPlusIcon}
            srcActive={personPlusActiveIcon}
          />
        </Space>
      ),
    },
  ];

  //fetching project list
  useEffect(() => {
    const payload = {
      page: currentPage,
      search: searchTerm,
    };
    dispatch(getProjectList(payload));
  }, [currentPage, searchTerm]);

  //tranforming project list
  const transformedProjectList = projectList?.map((project) => {
    const partnerName = project?.Projects_Partners_Mappings?.map((partner) =>
      partner.partner?.partner_name ? partner.partner?.partner_name : 'NA',
    );
    const partnerCode = project?.Projects_Partners_Mappings?.map((partner) =>
      partner.partner?.partner_code ? partner.partner?.partner_code : 'NA',
    );
    const partnerType = project?.Projects_Partners_Mappings?.map((partner) =>
      partner.PartnerType?.type ? partner.PartnerType?.type : 'NA',
    );
    return {
      id: project?.id,
      project_name: project?.project_name,
      project_id: project?.project_code,
      partner_name: [...new Set(partnerName)].join(', '),
      partner_type: [...new Set(partnerType)].join(', '),
      partner_code: [...new Set(partnerCode)].join(', '),
      start_date: project?.project_start_date
        ? moment(project?.project_start_date).format('DD MMM YY')
        : '-',
      end_date: project?.project_end_date
        ? moment(project?.project_end_date).format('DD MMM YY')
        : '-',
      actual_end_date: project?.actual_end_date
        ? moment(project?.actual_end_date).format('DD MMM YY')
        : '-',
    };
  });

  return (
    <Flex vertical>
      {/* header */}
      <div className="flex items-center justify-between px-6 py-5">
        <Flex vertical gap={'4px'}>
          <h1 className="text-lg font-segoe-ui text-tertiary-text">Projects</h1>
          <p className="text-xs sm:text-sm font-segoe-ui text-tertiary-placeholder">
            Keep track of project and their details.
          </p>
        </Flex>
        <SearchBar onChange={setSearchTerm} disabled={projectListLoading} />
      </div>

      {/* content */}
      <Flex className="relative w-full mb-2 border-t border-gray-200 sticky-footer">
        <Table
          onRow={(record) => ({
            onClick: (e) => {
              e.stopPropagation();
              navigate(`/dashboard/project/${record.id}`);
            },
          })}
          totalPages={pagination?.total_page}
          pagination={true}
          pageSize={pagination?.total_perpage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          columns={columns}
          bordered
          dataSource={transformedProjectList}
          spanWidth={1280}
          loading={projectListLoading}
        />
      </Flex>
    </Flex>
  );
}
