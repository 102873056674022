import Card from '@common/Card';

export default function Admin() {
  return (
    <Card
      className="my-2 md:mt-8 md:mb-4"
      headerClasses={'font-segoe-ui font-semibold text-2xl text-primary-bg'}
      header={'Welcome! Admin'}
    >
      <Card
        className={
          'mt-5 w-full p-5 rounded-lg !bg-secondary-bg font-segoe-ui shadow-equal-sides'
        }
      >
        Admin
      </Card>
    </Card>
  );
}
