import loginImg from '@assets/images/login.png';
import logoIcon from '@assets/icons/granules-logo.svg';
import emailIcon from '@assets/icons/input/email.svg';
import FormInput from '@common/FormInput';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthSlice, sendOtpRequest } from '../redux/slice';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { requestOtpLoading } = useSelector(selectAuthSlice);

  //handle login
  const handleLogin = (values) => {
    dispatch(sendOtpRequest({ email: values.email })).then((res) => {
      if (res?.payload?.success) {
        navigate('/otp', { state: values.email });
      }
    });
  };

  return (
    <div className="flex flex-col justify-center items-center md:grid  md:grid-cols-[3fr_2fr] h-screen">
      {/* login image */}
      <img
        src={loginImg}
        alt="login image"
        className="hidden m-0 md:block md:mx-auto md:my-auto"
      />
      <img
        src={logoIcon}
        alt="granules logo"
        className="block w-32 mb-16 md:mb-0 sm:w-40 lg:w-auto md:hidden"
      />

      {/* login form */}
      <div className="flex flex-col items-center justify-between w-[90%] xl:w-3/4">
        <img
          src={loginImg}
          alt="login image"
          className="block m-0 md:mx-auto md:my-auto md:hidden"
        />
        <img
          src={logoIcon}
          alt="granules logo"
          className="hidden w-1/2 lg:w-auto md:block"
        />
        <h1 className="mt-6 mb-6 text-2xl font-semibold lg:mt-10 lg:text-3xl font-segoe-ui text-primary-bg">
          Let's get started
        </h1>
        <Form className="flex flex-col w-full gap-5" onFinish={handleLogin}>
          <FormInput
            name="email"
            placeholder={'Enter your email'}
            prefix={<img src={emailIcon} className="mr-0.5" />}
            required
            validateMsg={'Please enter your email id'}
          />
          <Button
            htmlType="submit"
            className="!m-0 font-segoe-ui !py-5 rounded-lg px-[18px] w-full bg-primary-bg text-white font-semibold !text-sm"
            loading={requestOtpLoading}
          >
            Get OTP
          </Button>
        </Form>
      </div>
    </div>
  );
}
