import { Button, Flex, Form } from 'antd';
import Modal from './Modal';
import { CloseOutlined } from '@ant-design/icons';
import FormInput from './FormInput';


export default function ModalCtx({
  fieldName,
  onApprove,
  isOpen,
  loading,
  onClose,
  fieldPlaceholder,
  headerTitle,
  description,
  agreeBtnClasses,
  agreeText,
}) {
  const [form] = Form.useForm();
  return (
    <Form
      id="context_modal_form"
      onFinish={(values) => onApprove(values)}
      form={form}
      layout="vertical"
    >
      <Modal
        isOpen={isOpen}
        btnLoading={loading}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
        onAgree={() => {
          const values = form.getFieldsValue();
          onApprove(values);
        }}
        onCancel={() => {
          onClose();
          form.resetFields();
        }}
        headerTitle={headerTitle}
        centered
        cancelText={'Cancel'}
        agreeText={agreeText}
        agreeBtnClasses={agreeBtnClasses}
        spanWidth={'!w-[80vw] md:!w-[50vw] lg:!w-[28vw]'}
      >
        <Flex vertical gap={'0.3rem'}>
          <span className="text-sm text-tertiary-text font-segoe-ui">
            {description}
          </span>
          <div className="">
            <FormInput
              type="textarea"
              name={fieldName}
              placeholder={fieldPlaceholder}
              noStyle
            />
          </div>
        </Flex>
      </Modal>
    </Form>
  );
}
