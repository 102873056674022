import { useNavigate } from 'react-router-dom';
import { getLoggedInUser, TabTitle } from '@utils/helpers';

import DashboardPage from './pages/DashboardPage';
import Admin from '@features/admin';
import { ADMIN } from '@utils/roleRights';

export default function Dashboard() {
  TabTitle('Dashboard | Granules Alliance');
  const navigate = useNavigate();
  const { role } = getLoggedInUser();

  return role === ADMIN ? <Admin /> : <DashboardPage />;
}
