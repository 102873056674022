import Label from '@common/Label';
import Upload from '@common/Upload';
import { Button, message, Space } from 'antd';
import { useEffect, useState } from 'react';
import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { STATUS } from '@utils/helpers';
import moment from 'moment';
import { apiUrl, getLocalToken } from '@utils/apiClient';
import { uploadScoreCard } from '../redux/slice';
import { useDispatch } from 'react-redux';
import { getProjectDetails } from '@features/project/redux/slice';

const getStatus = (status) => {
  switch (status) {
    case STATUS.completed:
      return (
        <>
          <div className="p-1 rounded-full bg-primary-green"></div>
          <label>Completed</label>
        </>
      );
    case STATUS.inProgress:
      return (
        <>
          <div className="p-1 rounded-full bg-tertiary-placeholder"></div>
          <label>In Progress</label>
        </>
      );
    default:
      return 'NA';
  }
};

export default function PartnerProjDetails({ data }) {
  const [file, setFile] = useState([]);
  const [overallRating, setOverallRating] = useState(null);
  const [downloadingId, setDownloadingId] = useState(null);
  const dispatch = useDispatch();

  //download project details excel file
  const handleDownloadScoreSheet = async (id) => {
    setDownloadingId(id);
    try {
      const params = new URLSearchParams({
        partner_user_mapping_id: String(id),
      });
      const response = await fetch(
        `${apiUrl}/activity/export-scorecard?${params}`,
        {
          headers: {
            Authorization: getLocalToken(),
          },
        },
      );
      if (!response.ok) {
        throw new Error('Failed to download score card...');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', 'score-card.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setDownloadingId(null);
      message.error(error.message || 'Failed to download score card...');
    } finally {
      setDownloadingId(null);
    }
  };

  const labelClasses =
    'text-tertiary-placeholder text-xs font-segoe-ui w-full sm:w-[20%] md:w-[10%]';
  const descriptionClasses =
    'text-tertiary-placeholder w-[90%] text-xs font-segoe-ui';
  const containerClasses = 'flex flex-col sm:flex-row w-full items-start gap-2';

  //uploading files on file state change
  useEffect(() => {
    const [uploadedFile] = file;
    if (uploadedFile) {
      const fd = new FormData();
      fd.append('file', uploadedFile);
      fd.append('partner_user_mapping_id', data?.partnerDetails?.id);
      dispatch(uploadScoreCard(fd)).then((res) => {
        setFile([]);

        dispatch(getProjectDetails(data?.projectDetails.id)).then((res) => {
          if (res?.payload?.success) {
            const foundPartner =
              res?.payload?.data?.Projects_Partners_Mappings?.find(
                (pa) => pa.id === data?.partnerDetails?.id,
              );
            if (foundPartner) {
              setOverallRating(foundPartner.rating);
            }
          }
        });
      });
    }
  }, [file]);

  return (
    <div>
      {/* partner name */}
      <div className="px-2 py-1 font-semibold rounded-md text-primary-bg bg-secondary-screen w-fit text-2xs font-segoe-ui">
        {data?.partnerDetails?.partner?.partner_name}
      </div>
      {/* project name */}
      <div className="mt-2 text-lg font-semibold font-segoe-ui text-tertiary-text">
        {data?.projectDetails?.project_name}
      </div>

      {/* project details */}
      <div className="flex flex-col w-full gap-3 mt-5">
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Status'}
          description={
            <Space size="small">
              {getStatus(data?.projectDetails?.status)}
            </Space>
          }
        />
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Completed date'}
          description={moment(data?.projectDetails?.project_end_date).format(
            'DD MMMM YYYY',
          )}
        />
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Partner Type'}
          description={
            <div className="px-2 py-1 font-semibold rounded-md text-primary-bg bg-secondary-screen w-fit text-2xs font-segoe-ui">
              {data?.partnerTypeDetails?.type}
            </div>
          }
        />
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Description'}
          description={data?.projectDetails?.project_desc}
        />
        <Label
          containerClasses={containerClasses}
          labelClasses={labelClasses}
          descriptionClasses={descriptionClasses}
          label={'Overall Rating'}
          description={
            overallRating
              ? overallRating
              : data?.overall_rating
                ? data?.overall_rating
                : '-'
          }
        />
      </div>

      {/* action buttons */}
      <div className="flex items-center gap-4 mt-6">
        <Button
          className="!m-0 !p-0 !font-segoe-ui !text-xs !font-semibold"
          type="link"
          icon={<DownloadOutlined />}
          onClick={() => handleDownloadScoreSheet(data?.partnerDetails?.id)}
          loading={downloadingId}
        >
          Download
        </Button>
        <Upload files={file} setFiles={setFile} allowedFileTypes={['.xlsx']}>
          <Button
            className="!m-0 !p-0 !font-segoe-ui !text-xs !font-semibold"
            type="link"
            icon={<CloudUploadOutlined />}
          >
            Upload
          </Button>
        </Upload>
      </div>
    </div>
  );
}
