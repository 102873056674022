import FormInput from '@common/FormInput';
import { getPartners, selectMastersSlice } from '@features/masterRedux/slice';
import { Col, Form, Row } from 'antd';
import Modal from 'common/Modal';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import emailIcon from '@assets/icons/input/email.svg';
import { reAssignPartner } from '../redux/slice';

export default function ShortCloseModal({
  isOpen,
  onClose,
  project,
  partner,
  onReassign,
  loading,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { partnersLoading, partnersList } = useSelector(selectMastersSlice);

  //transforming partners list
  const transformedPartnersList = partnersList?.map((pa) => {
    return { label: pa.partner_name, value: pa.id };
  });

  //handle craete partner form finish
  const handleOnSubmit = (values) => {
    const payload = {
      ...values,
      project_id: project.id,
      partner_id: partner.partner?.partner_id,
      partner_type_id: partner.partner?.partner_type_id,
      from_partner_user_id: partner.partner?.partner_user_id,
      to_partner_user_id: values.reassign_to,
    };
    dispatch(reAssignPartner(payload)).then((res) => {
      if (res?.payload?.success) {
        onReassign();
      }
    });
  };

  //structoring the partner addresses state on for values change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.reassign_to) {
      const to_email = partnersList.find(
        (pa) => pa.id === values.reassign_to,
      )?.partner_email;
      form.setFieldsValue({ to_email: to_email });
    }
  };

  //populating form values
  useEffect(() => {
    form.setFieldsValue({
      reassign_from: partner.partner?.partner_id,
      cc: 'admin@granules.com',
    });
  }, [partner, project, isOpen]);

  //fetching partners
  useEffect(() => {
    if (isOpen) {
      dispatch(getPartners());
    }
  }, [isOpen]);

  return (
    <Modal
      btnLoading={partnersLoading || loading}
      headerTitle="Reassign"
      isOpen={isOpen}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      onAgree={() => form.submit()}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      agreeText="Reassign"
      cancelText="Cancel"
      spanWidth={' !w-[80vw] md:!w-[40vw] lg:!w-[30vw]'}
    >
      <Form
        id="reassign_partner_form"
        className="flex flex-col justify-center gap-4"
        layout="vertical"
        onFinish={handleOnSubmit}
        onValuesChange={handleFormValuesChange}
        form={form}
      >
        <Col span={24}>
          <FormInput
            name={'reassign_from'}
            options={transformedPartnersList}
            disabled
            label={'Reassign From'}
            type="select"
          />
        </Col>
        <Col span={24}>
          <FormInput
            name={'reassign_to'}
            options={transformedPartnersList}
            placeholder="Select partner"
            optionRender={(option) => {
              return (
                <span className="text-xs font-segoe-ui text-tertiary-text">
                  {option.label}
                </span>
              );
            }}
            loading={partnersLoading}
            label={'Reassign To'}
            type="select"
          />
        </Col>
        <Col span={24}>
          <FormInput
            name={'to_email'}
            label={'To'}
            prefix={<img src={emailIcon} />}
            disabled
          />
        </Col>
        <Col span={24}>
          <FormInput
            name={'cc'}
            label={'CC'}
            prefix={<img src={emailIcon} />}
            disabled
          />
        </Col>
      </Form>
    </Modal>
  );
}
