import React, { useState, useEffect } from 'react';
export default function CountdownBtn({ onClick, className }) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const handleClick = () => {
    setIsDisabled(true);
    setCountdown(30);
  };

  useEffect(() => {
    let timer;
    if (isDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsDisabled(false);
    }

    return () => clearInterval(timer);
  }, [isDisabled, countdown]);

  return (
    <button
      onClick={() => {
        onClick();
        handleClick()
      }}
      disabled={isDisabled}
      className={className}
    >
      {isDisabled ? `Please wait ${countdown}s` : 'Click to resend'}
    </button>
  );
}
