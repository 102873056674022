import { CloseOutlined } from '@ant-design/icons';
import { Modal as AntModal, Button, Flex, Spin } from 'antd';

export default function Modal({
  isOpen,
  onClose,
  footerComponent,
  onAgree,
  header,
  agreeText = 'Add values',
  cancelText = 'Clear',
  children,
  onCancel,
  headerTitle,
  centered = false,
  btnLoading,
  agreeBtnClasses,
  spanWidth,
  headerTitleClasses,
}) {
  return (
    <AntModal
      centered={centered}
      classNames={{
        content: 'p-0',
        body: 'max-h-[75vh] overflow-auto',
      }}
      title={
        header ? (
          header
        ) : (
          <div className="flex items-center justify-between p-0">
            <h3
              className={
                headerTitleClasses ||
                'text-lg font-semibold text-tertiary-text font-segoe-ui'
              }
            >
              {headerTitle}
            </h3>
            <Button
              className="bg-transparent border-none !m-0 !p-0"
              icon={<CloseOutlined />}
              shape="circle"
              onClick={onClose}
            />
          </div>
        )
      }
      footer={
        footerComponent ? (
          footerComponent
        ) : (
          <Flex justify="center" gap={10}>
            <Button
              className="!p-4.5 font-medium border w-full border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
              onClick={onCancel}
            >
              {cancelText}
            </Button>
            <Button
              className={
                agreeBtnClasses ||
                '!p-4.5 font-medium border w-full text-md font-segoe-ui bg-primary-bg text-secondary-bg hover:!bg-transparent hover:!text-primary-bg hover:!border-primary-bg'
              }
              onClick={onAgree}
              loading={btnLoading}
            >
              {agreeText}
            </Button>
          </Flex>
        )
      }
      confirmLoading={btnLoading}
      open={isOpen}
      closable={false}
      className={spanWidth}
    >
      <Spin spinning={btnLoading}>{children}</Spin>
    </AntModal>
  );
}
