import { Avatar, Layout } from 'antd';
import logoIcon from '@assets/icons/granules-logo.svg';
import avatarImg from '@assets/images/avatar.png';
import ProfileDropdown from './ProfileDropdown';
import Navlinks from './NavLinks';

export default function Header() {
  return (
    <Layout.Header className="sticky top-0 z-20 flex items-center justify-between px-6 py-9 sm:px-12 bg-secondary-bg">
      <img src={logoIcon} alt="granules-logo" className="w-24 sm:w-32" />
      <Navlinks />
      <ProfileDropdown>
        <Avatar shape="square" className='cursor-pointer' size={40} icon={<img src={avatarImg} />} />
      </ProfileDropdown>
    </Layout.Header>
  );
}
