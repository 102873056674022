import apiClient from '@utils/apiClient';

// create partner
export const partnerCreate = (payload) => {
  return apiClient.post(apiClient.Urls.createPartner, payload, true);
};

// project by partner id
export const projectsByPartnerId = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.projectsByPartner}/${payload.id}`,
    payload,
    true,
  );
};

// create partner user
export const partnerUserCreate = (payload) => {
  return apiClient.post(`${apiClient.Urls.createPartnerUser}`, payload, true);
};
