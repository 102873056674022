import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { Button, Col, Descriptions, Flex, Form, Row } from 'antd';
import PartnerProjDetails from './components/PartnerProjDetails';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectsByDateRange, selectPartnerScoreSlice } from './redux/slice';
import {
  getProjectDetails,
  selectProjectSlice,
} from '@features/project/redux/slice';

export default function PartnerScore() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [transformedProjectPartners, setTransformedProjectPartners] = useState(
    [],
  );
  const [transformedPartnerTypes, setTransformedPartnersTypes] = useState([]);
  const [structuredData, setStructuredData] = useState({
    partnerDetails: null,
    partnerTypeDetails: null,
    projectDetails: null,
    overall_rating: '',
  });
  const { projectsByDateRange, projectsByDateRangeLoading } = useSelector(
    selectPartnerScoreSlice,
  );
  const { projectDetailsLoading, projectDetailsData } =
    useSelector(selectProjectSlice);

  //tranforming projects
  const transformedProjects = projectsByDateRange?.map((project) => {
    return { label: project.project_name, value: project.id };
  });

  //handle fetch project score
  const handleOnSubmit = (values) => {};

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.date_range) {
      const payload = {
        startDate: values.date_range[0],
        endDate: values.date_range[1],
      };
      dispatch(getProjectsByDateRange(payload));
    }

    if (changedVal.project) {
      dispatch(getProjectDetails(values.project)).then((res) => {
        if (res?.payload?.success) {
          const data = res?.payload?.data?.Projects_Partners_Mappings;
          const transformedPartners = data?.map((partner) => {
            return {
              label: partner.partner.partner_name,
              value: partner.id,
              partnerType: partner.PartnerType,
            };
          });
          setTransformedProjectPartners(transformedPartners);
        }
      });
      form.resetFields(['partner', 'partner_type']);
    }

    if (changedVal.partner) {
      const tranformedPartnerTypes = transformedProjectPartners.map((type) => {
        return { label: type.partnerType.type, value: type.partnerType.id };
      });
      setTransformedPartnersTypes(tranformedPartnerTypes);
      form.resetFields(['partner_type']);

      const foundPartner = projectDetailsData?.Projects_Partners_Mappings?.find((pa) => pa.id === values.partner);

      if (foundPartner) {
        setStructuredData({
          ...structuredData,
          overall_rating: foundPartner.rating,
        });
      }
    }

    if (changedVal.partner_type) {
      const partnerDetails =
        projectDetailsData?.Projects_Partners_Mappings.find(
          (partner) => partner.id === values.partner,
        );

      setStructuredData({
        ...structuredData,
        partnerTypeDetails: partnerDetails.PartnerType,
        partnerDetails,
        projectDetails: projectDetailsData,
      });
    }
  };

  return (
    <Form layout="vertical" form={form} onValuesChange={handleFormValuesChange}>
      <Card
        loading={projectDetailsLoading || projectsByDateRangeLoading}
        header={
          <>
            <h3 className="text-xl font-semibold font-segoe-ui text-tertiary-placeholder">
              Partner Score
            </h3>
            <FormInput
              name={'date_range'}
              type="date_range"
              placeholder={['Start date', 'End date']}
            />
          </>
        }
        headerClasses={'flex justify-between items-center'}
        className={
          'w-full rounded-lg !bg-secondary-bg px-6 py-5 mt-8 font-segoe-ui shadow-equal-sides'
        }
      >
        <Flex vertical gap={'2.25rem'}>
          {/* form */}
          <Row gutter={16} className="mt-4">
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                labelClasess={
                  'text-xs text-tertiary-text font-segoe-ui font-semibold'
                }
                name={'project'}
                label={'Project'}
                placeholder="Please select project"
                type="select"
                options={transformedProjects}
                optionRender={(option) => {
                  return (
                    <span className="text-xs font-segoe-ui text-tertiary-text">
                      {option.label}
                    </span>
                  );
                }}
              />
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                labelClasess={
                  'text-xs text-tertiary-text font-segoe-ui font-semibold'
                }
                name={'partner'}
                label={'Partner'}
                placeholder="Please select partner"
                type="select"
                options={transformedProjectPartners}
                optionRender={(option) => {
                  return (
                    <span className="text-xs font-segoe-ui text-tertiary-text">
                      {option.label}
                    </span>
                  );
                }}
              />
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <FormInput
                labelClasess={
                  'text-xs text-tertiary-text font-segoe-ui font-semibold'
                }
                name={'partner_type'}
                label={'Partner Type'}
                placeholder="Please select partner type"
                type="select"
                options={transformedPartnerTypes}
                optionRender={(option) => {
                  return (
                    <span className="text-xs font-segoe-ui text-tertiary-text">
                      {option.label}
                    </span>
                  );
                }}
              />
            </Col>
          </Row>

          {/* details */}
          {structuredData.partnerTypeDetails && (
            <>
              <PartnerProjDetails data={structuredData} />

              {/* save */}
              {/* <Button
                onClick={() => form.submit()}
                className="!p-4.5 font-medium bg-primary-bg w-fit text-secondary-bg hover:border hover:border-primary-bg hover:!bg-transparent hover:!text-primary-bg text-md font-segoe-ui"
              >
                Save
              </Button> */}
            </>
          )}
        </Flex>
      </Card>
    </Form>
  );
}
