import React from 'react';
import { Modal,Flex, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';



export const openNotification = ({
  onApprove,
  onCancel,
  cancelText = 'No',
  approveText = 'Save',
  title,
  subTitle,
  type = 'info',
  approveBtnClasses,
  cancelBtnClasses,
  icon,
  titleClasses,
  subTitleClasses,
  hideIcon = false,
  okBtnLoading = false,
  extraBtns,
}) => {
  const modalIcon =
    icon ||
    {
      info: <ExclamationCircleOutlined style={{ color: '#1890ff' }} />,
      success: <ExclamationCircleOutlined style={{ color: '#52c41a' }} />,
      error: <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />,
      warning: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
    }[type];

  Modal.confirm({
    title: (
      <h2 className={titleClasses || 'text-lg font-semibold text-tertiary-text'}>
        {title}
      </h2>
    ),
    content: (
      <span className={subTitleClasses || 'text-sm text-tertiary-placeholder'}>
        {subTitle}
      </span>
    ),
    icon: !hideIcon ? <div className="hidden"></div> : modalIcon,
    centered: true,
    closable: true,
    maskClosable: false,
    okText: approveText,
    cancelText: cancelText,
    onOk: onApprove,
    onCancel: onCancel,
    okButtonProps: { className: approveBtnClasses },
    cancelButtonProps: { className: cancelBtnClasses },
    footer: (_, { OkBtn, CancelBtn }) => (
      <Spin spinning={okBtnLoading}>
        <Flex justify="center" align="center" gap={10} className="mt-4">
          <CancelBtn />
          <OkBtn />
          {extraBtns}
        </Flex>
      </Spin>
    ),
  });
};
