import { message } from 'antd';
import { useRef, useState } from 'react';

export default function Upload({
  setFiles,
  files,
  limit,
  allowedFileTypes,
  disabled = false,
  children,
  multiple = false,
}) {
  const fileInput = useRef(null);

  const handleFileInputChange = (e) => {
    const uploadedFiles = e.target.files;
    if (uploadedFiles?.length) {
      const selectedFiles = Array.from(uploadedFiles);

      if (multiple) {
        const duplicateFiles = selectedFiles.filter((selectedFile) =>
          files.some((existingFile) => existingFile.name === selectedFile.name),
        );

        if (duplicateFiles.length > 0) {
          message.error({
            content: `The following files are already uploaded: ${duplicateFiles.map((file) => file.name).join(', ')}`,
            duration: 5,
          });
          e.target.value = '';
          return;
        }

        if (limit && files.length + selectedFiles.length > limit) {
          message.error({
            content: `You can only upload up to ${limit} documents. Please upload important documents only.`,
            duration: 5,
          });
          e.target.value = '';

          return;
        } else {
          setFiles([...files, ...selectedFiles]);
          e.target.value = '';
        }
      } else {
        setFiles(selectedFiles);
        e.target.value = '';
      }
    }
  };

  const handleUploadClick = () => {
    const btn = fileInput.current;
    if (btn) {
      btn.click();
    }
  };

  return (
    <div className={''} onClick={handleUploadClick}>
      <input
        ref={fileInput}
        type="file"
        multiple={multiple}
        onChange={handleFileInputChange}
        accept={allowedFileTypes?.join(', ')}
        style={{ display: 'none' }}
        disabled={disabled}
      />
      {children}
    </div>
  );
}
