import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { partnerCreate, partnerUserCreate, projectsByPartnerId } from './api';

const actions = {
  CREATE_PARTNER: 'partner/CREATE_PARTNER',
  PROJECT_BY_PARTNER: 'partner/PROJECT_BY_PARTNER',
  CREATE_PARTNER_USER: 'partner/CREATE_PARTNER_USER',
};

export const createPartner = createAsyncThunk(
  actions.CREATE_PARTNER,
  async (payload) => {
    const response = await partnerCreate(payload);
    return response;
  },
);

export const getProjectsByParterId = createAsyncThunk(
  actions.PROJECT_BY_PARTNER,
  async (payload) => {
    const response = await projectsByPartnerId(payload);
    return response;
  },
);

export const createPartnerUser = createAsyncThunk(
  actions.CREATE_PARTNER_USER,
  async (payload) => {
    const response = await partnerUserCreate(payload);
    return response;
  },
);

const initialState = {
  createPartnerLoading: false,
  projectsByPartner: [],
  projectsByPartnerLoading: false,
  pagination: null,
  createPartnerUserLoading: false,
};

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create partner
    builder
      .addCase(createPartner.pending, (state) => {
        state.createPartnerLoading = true;
      })
      .addCase(createPartner.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createPartnerLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createPartner.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.createPartnerLoading = false;
        message.error(errorMsg);
      });

    // projects by partner
    builder
      .addCase(getProjectsByParterId.pending, (state) => {
        state.projectsByPartnerLoading = true;
      })
      .addCase(getProjectsByParterId.fulfilled, (state, action) => {
        const { success, message: msg, data, pagination } = action.payload;
        state.projectsByPartnerLoading = false;

        if (success) {
          state.projectsByPartner = data;
          state.pagination = pagination;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectsByParterId.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.projectsByPartnerLoading = false;
        message.error(errorMsg);
      });

    //create partner user
    builder
      .addCase(createPartnerUser.pending, (state) => {
        state.createPartnerUserLoading = true;
      })
      .addCase(createPartnerUser.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;
        state.createPartnerUserLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createPartnerUser.rejected, (state, action) => {
        const errorMsg = action.payload.message;
        state.createPartnerUserLoading = false;
        message.error(errorMsg);
      });
  },
});

export const selectPartnerSlice = (state) => {
  return state.partner;
};

export default partnerSlice.reducer;
