import apiClient from '@utils/apiClient';

// create activity
export const activityCreate = (payload) => {
  return apiClient.post(apiClient.Urls.createActivity, payload, true);
};

// fetch activity
export const activityById = (payload) => {
  return apiClient.get(`${apiClient.Urls.activityById}/${payload}`, {}, true);
};

// update activity
export const activityUpdate = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.updateActivity}/${payload.id}`,
    payload,
    true,
  );
};

// remove activity
export const removeActivity = (id) => {
  return apiClient.patch(
    `${apiClient.Urls.deleteActivity}/${id}`,
    {},
    true,
  );
};

// audit trail
export const auditTrailsById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.auditTrailsById}/${payload.id}`,
    {},
    true,
  );
};

// audit trail by partner
export const auditTrailsByPartnerId = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.auditTrailsByPartnerId}/${payload.id}`,
    {},
    true,
  );
};
