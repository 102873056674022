import checkboxIcon from '@assets/icons/input/checkbox.svg';
import checkbocActiveIcon from '@assets/icons/input/checkbox_active.svg';

export default function CheckboxGroup({
  containerClasses,
  optionClasses,
  options,
  checked = [],
  setChecked,
  active = null,
  setActive,
  lastUnChecked = null,
  setLastUnChecked,
}) {
  //handle click on option btn
  const handleOptionClick = (value) => {
    if (checked.includes(value)) {
      if (active === value) {
        const [unChecked] = [...checked].filter((val) => val === value);
        setLastUnChecked(unChecked);
        const filteredValuesChecked = [...checked].filter(
          (val) => val !== value,
        );
        setChecked(filteredValuesChecked);
        setActive(null);
      } else {
        setActive(value);
        setLastUnChecked(null);
      }
    } else {
      setChecked([...checked, value]);
      setActive(value);
      setLastUnChecked(null);
    }
  };

  return (
    <div
      className={
        containerClasses || 'rounded-t-lg flex w-fit shadow-equal-sides-dark'
      }
    >
      {options.map((option) => {
        const isChecked = checked.includes(option.value);
        return (
          <div
            key={option.value}
            className={
              optionClasses ||
              `!py-2.5 ${option.value === options.length ? 'border-r-none' : 'border-r-2'} !px-5 text-sm  font-segoe-ui flex gap-2 items-center cursor-pointer hover:bg-tertiary-bg ${isChecked ? 'text-primary-bg' : 'text-tertiary-text'} ${active === option.value ? 'bg-primary-screen' : 'bg-transparent'}`
            }
            onClick={() => handleOptionClick(option.value)}
          >
            {isChecked ? (
              <img src={checkbocActiveIcon} />
            ) : (
              <img src={checkboxIcon} />
            )}
            {option.label}
          </div>
        );
      })}
    </div>
  );
}
