import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { Button, Col, Form, message, Row, Space, Tag } from 'antd';
import { useState } from 'react';
import PartnerAddress from '../components/PartnerAddress';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import trashcanIcon from '@assets/icons/input/trashcan.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createPartner, selectPartnerSlice } from '../redux/slice';
import debounce from 'lodash/debounce';
import {
  checkDupPartnerEmail,
  selectMastersSlice,
} from '@features/masterRedux/slice';

const DUMMY = [
  {
    address: 'Chennai',
    contacts: [
      {
        contact_name: 'ShravanKumar',
        phone_number: '9999999999',
        email_id: 'sravanr1991@gmail.com',
      },
      {
        contact_name: 'BimaRaju',
        phone_number: '9999999998',
        email_id: 'bimar998@gmail.com',
      },
    ],
  },
  {
    address: 'Hyderabad',
    contacts: [
      {
        contact_name: 'Rohith',
        phone_number: '9999999997',
        email_id: 'rohit1993@gmail.com',
      },
      {
        contact_name: 'Avinash',
        phone_number: '9999999996',
        email_id: 'avi08@gmail.com',
      },
    ],
  },
];

export default function CreatePartner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createPartnerLoading } = useSelector(selectPartnerSlice);
  const { checkDupPartnerEmailLoading, partnerEmailAlreadyExist } =
    useSelector(selectMastersSlice);

  const [form] = Form.useForm();

  //partner addrees state
  const [partnerAddresses, setPartnerAddresses] = useState([
    {
      address: '',
      contacts: [],
    },
  ]);
  const [selectedAddresses, setSelectedAddresses] = useState(0);

  // adding new address tab for the partner
  const handleAddNewAddress = () => {
    setPartnerAddresses([
      ...partnerAddresses,
      {
        address: '',
        contacts: [],
      },
    ]);
    setSelectedAddresses(partnerAddresses.length);
  };

  //handle craete partner form finish
  const handleOnSubmit = (values) => {
    const payload = {
      ...values,
      addresses: partnerAddresses,
    };
    dispatch(createPartner(payload)).then((res) => {
      if (res?.payload?.success) {
        navigate('/', { replace: true });
      }
    });
  };

  //debouncing the prokect name
  const debouncedCheckPartnerEmail = debounce((partner_email) => {
    dispatch(checkDupPartnerEmail({ partner_email }));
  }, 500);

  //handling form value change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.partner_email) {
      debouncedCheckPartnerEmail(values.partner_email);
    }
  };

  //validating partner email for duplications
  const validatePartnerEmail = (_, value) => {
    if (partnerEmailAlreadyExist) {
      return Promise.reject(
        new Error(
          'Partner with similar email already exist, please enter different partner email.',
        ),
      );
    }
    return Promise.resolve();
  };

  return (
    <Card
      className="my-2 md:my-8"
      headerClasses={'font-segoe-ui font-semibold text-2xl text-primary-bg'}
      header={'Create Partner'}
    >
      <Card
        className={
          'mt-5 w-full rounded-lg !bg-secondary-bg p-7 font-segoe-ui shadow-equal-sides'
        }
        loading={createPartnerLoading}
      >
        <Form
          form={form}
          layout="vertical"
          id="create-partner-form"
          onFinish={handleOnSubmit}
          onValuesChange={handleFormValuesChange}
          className="flex flex-col gap-5"
        >
          <Row gutter={24}>
            <Col span={24} md={{ span: 12 }}>
              <FormInput
                label={'Partner Name'}
                name={'partner_name'}
                placeholder="Enter partner name"
              />
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <FormInput
                label={
                  <Space>
                    <span>Partner Email Id</span>
                    {checkDupPartnerEmailLoading && (
                      <LoadingOutlined spin={checkDupPartnerEmailLoading} />
                    )}
                  </Space>
                }
                name={'partner_email'}
                placeholder="Enter email id"
                rules={[
                  { required: true, message: 'Partner email is required' },
                  { validator: validatePartnerEmail },
                ]}
                hideColon
              />
            </Col>
          </Row>

          {/* address form */}
          <div>
            <div className="flex w-full overflow-x-auto scrollbar-1">
              {[...partnerAddresses, { isBtn: true }].map((address, i) => {
                return address?.isBtn ? (
                  <Button
                    icon={<PlusOutlined />}
                    className="text-primary-bg font-semibold text-sm font-segoe-ui !border-none hover:!text-secondary-bg hover:!bg-primary-bg"
                    onClick={handleAddNewAddress}
                  >
                    Add Address
                  </Button>
                ) : (
                  <div
                    key={i}
                    className={`${
                      i === selectedAddresses
                        ? 'bg-tertiary-bg cursor-not-allowed'
                        : 'bg-transparent hover:text-primary-bg'
                    } py-2 px-4 rounded-t-lg shrink-0 cursor-pointer font-segoe-ui text-xs md:text-sm font-semibold text-tertiary-text select-none flex items-center gap-2`}
                    onClick={() => setSelectedAddresses(i)}
                  >
                    <span>
                      {partnerAddresses.length > 1
                        ? `Address ${(i + 1).toString().padStart(2, '0')}`
                        : 'Partner Address'}
                    </span>
                    {/* address delete */}
                    {partnerAddresses.length > 1 && selectedAddresses !== i && (
                      <img
                        src={trashcanIcon}
                        className="hover:opacity-50"
                        onClick={(e) => {
                          e.stopPropagation();
                          const filteredAddressList = [
                            ...partnerAddresses,
                          ].filter(
                            (address) =>
                              partnerAddresses.indexOf(address) !== i,
                          );
                          setPartnerAddresses(filteredAddressList);
                          if (selectedAddresses === 0) return;
                          setSelectedAddresses(selectedAddresses - 1);
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>

            {/* partner address form */}
            <div className="bg-tertiary-bg rounded-b-lg p-2.5">
              <PartnerAddress
                address={partnerAddresses}
                setAddress={setPartnerAddresses}
                selectedAddress={selectedAddresses}
              />
            </div>
          </div>

          {/* action buttons */}
          <Space size="middle" className="flex items-center justify-center">
            <Button
              className="!p-4.5 font-medium border border-primary-placeholder text-tertiary-text bg-transparent text-md font-segoe-ui hover:!border-primary-bg hover:!text-primary-bg"
              onClick={() => navigate(-1)}
            >
              Back
            </Button>
            <Button
              onClick={() => form.submit()}
              className="!p-4.5 font-medium bg-primary-bg text-secondary-bg hover:border hover:border-primary-bg hover:!bg-transparent hover:!text-primary-bg text-md font-segoe-ui"
              loading={createPartnerLoading}
            >
              Create
            </Button>
          </Space>
        </Form>
      </Card>
    </Card>
  );
}
