export const GRANUAL_USER = 'GranualUser';
export const ADMIN = 'Admin';
export const PARTNER = 'Partner';
export const PARTNER_USER = 'PartnerUser';

export const PERMISSIONS = {
  //..............................PROJECT..............................
  canCreateProject: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  canEditProject: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  //..............................PARTNER..............................
  canCreatePartner: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  canShortClosePartner: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  //..............................PARTNER SCORE..............................
  canAddPartnerScore: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  //..............................ACTIVITY..............................
  canAcceptAcitivity: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  canReadActivityAuditTrails: (role) => {
    const allowedRoles = [GRANUAL_USER, PARTNER, PARTNER_USER];
    return allowedRoles.includes(role);
  },

  canToggleActivity: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  canCreateActivity: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  canCreateBulkActivity: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  canDeleteActivity: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },

  canStopActivity: (role) => {
    const allowedRoles = [GRANUAL_USER];
    return allowedRoles.includes(role);
  },
};
