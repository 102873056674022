import Card from '@common/Card';
import FormInput from '@common/FormInput';
import { useResponsiveness } from '@hooks/useResponsiveness';
import { Button, Col, Form, message, Row } from 'antd';
import { useEffect, useState } from 'react';
import expandIcon from '@assets/icons/input/arrow_down.svg';
import IconButton from '@common/IconButton';
import deleteIcon from '@assets/icons/input/trashcan.svg';

const PartnerDetailsForm = ({
  form,
  contacts,
  setContacts,
  selectedContact,
  setSelectedContact,
  parentForm,
}) => {
  //handle craete partner form finish
  const handleOnSubmit = (values) => {
    const isContactAlreadyExist = contacts.find(
      (cont) => cont.contact_name === values.contact_name,
    );
    if (isContactAlreadyExist) {
      message.error("Contact already exists by name " + values.contact_name);
      return;
    }
    setContacts([...contacts, { ...values }]);
    form.resetFields();
    setSelectedContact(null);
    parentForm.setFieldsValue({ partner_user: null });
  };

  //structoring the partner addresses state on for values change
  const handleFormValuesChange = (changedVal, values) => {};

  //loading selected contact to fields
  useEffect(() => {
    if (selectedContact) {
      form.setFieldsValue(selectedContact);
    }
  }, [selectedContact]);

  return (
    <Form
      form={form}
      layout="vertical"
      id="partner-address-form"
      onFinish={handleOnSubmit}
      //   onValuesChange={handleFormValuesChange}
      className="flex flex-col gap-5 !px-4"
    >
      <Row gutter={24}>
        <Col span={24} lg={{ span: 7 }}>
          <FormInput
            label={'Contact Name'}
            name={'contact_name'}
            placeholder="Enter contact name"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
          />
        </Col>
        <Col span={24} lg={{ span: 8 }}>
          <FormInput
            label={'Phone Number'}
            name={'phone_number'}
            placeholder="Enter phone number"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            rules={[
              {
                pattern:
                  /^(\(?\d{1,3}\)?[-.\s]?)?\d{1,3}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/,
                message: 'Please enter a valid phone number',
              },
            ]}
          />
        </Col>
        <Col span={24} lg={{ span: 7 }}>
          <FormInput
            label={'Email ID'}
            name={'email_id'}
            placeholder="Enter email id"
            labelClasess={'text-xs text-tertiary-text font-segoe-ui'}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email',
              },
              {
                required: true,
                message: 'Email is required',
              },
            ]}
            hideColon
          />
        </Col>
        <Col
          span={24}
          lg={{ span: 2 }}
          className="flex justify-center lg:justify-start"
        >
          <FormInput
            type="placeholder"
            hideLabel
            label={'Action'}
            placeholderComponent={
              <Button
                onClick={() => {
                  form.submit();
                }}
                className="px-6 md:px-4 !py-4.5 font-medium border border-primary-bg text-primary-bg bg-transparent text-sm font-segoe-ui"
              >
                Add
              </Button>
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

export default function PartnerAddress({
  address,
  setAddress,
  selectedAddress,
}) {
  const { xs, sm, md } = useResponsiveness();
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [form] = Form.useForm();
  const [childForm] = Form.useForm();

  //tranforming contacts for select field
  const transformedOptions = contacts?.map((contact, index) => ({
    label: `Partner User (${(index + 1).toString().padStart(2, '0')})`,
    value: index,
    name: contact.contact_name,
  }));

  //handle craete partner form finish
  const handleOnSubmit = (values) => {};

  //structoring the partner addresses state on for values change
  const handleFormValuesChange = (changedVal, values) => {
    if (changedVal.partner_user || changedVal.partner_user === 0) {
      setSelectedContact(contacts[changedVal.partner_user]);
    }

    if (changedVal.partner_address) {
      setAddress((prevAddress) => {
        const updatedAddress = [...prevAddress];
        updatedAddress[selectedAddress] = {
          ...updatedAddress[selectedAddress],
          address: values.partner_address,
        };
        return updatedAddress;
      });
    }
  };

  //handle remove user
  const handleRemoveUser = (index) => {
    setContacts((prevCont) => {
      const updatedContacts = [...prevCont].filter((con, i) => i !== index);
      return updatedContacts;
    });
    childForm.resetFields();
  };

  //setting / updating existing address log if new contact is added
  useEffect(() => {
    setAddress((prevAddress) => {
      const updatedAddress = [...prevAddress];
      updatedAddress[selectedAddress] = {
        ...updatedAddress[selectedAddress],
        contacts,
      };
      return updatedAddress;
    });
  }, [contacts?.length]);

  //loading selected contact to fields (reseting contact form)
  useEffect(() => {
    form.setFieldsValue({
      partner_address: address[selectedAddress]?.address,
      partner_user: null,
    });
    setContacts(address[selectedAddress]?.contacts);
    childForm.resetFields();
    setSelectedContact(null);
  }, [selectedAddress]);

  return (
    <Form
      form={form}
      layout="vertical"
      id="partner-address-form"
      //   onFinish={handleOnSubmit}
      onValuesChange={handleFormValuesChange}
      className="flex flex-col gap-5"
    >
      <FormInput
        type="textarea"
        placeholder="Enter partner address"
        rows={xs || sm || md ? 3 : 2}
        name={'partner_address'}
      />
      <Card
        header={
          <div className={contacts?.length ? 'px-2' : 'px-4'}>
            {contacts?.length ? (
              <FormInput
                type="select"
                name="partner_user"
                options={transformedOptions}
                showSearch={false}
                selectInputClasses={
                  'font-segoe-ui !p-0 !text-xs custom-select-label md:!text-sm !shadow-none max-w-fit'
                }
                suffixIcon={<img src={expandIcon} />}
                optionRender={(option) => {
                  return (
                    <div className="flex justify-between">
                      <span className="text-sm capitalize font-segoe-ui text-tertiary-placeholder">
                        {contacts[option.value]?.contact_name}
                      </span>
                      <img
                        onClick={() => handleRemoveUser(option.value)}
                        className="cursor-pointer hover:opacity-50"
                        src={deleteIcon}
                      />
                    </div>
                  );
                }}
                placeholder="Partner User"
                selectplaceholderClasses={
                  'font-segoe-ui font-semibold text-sm text-tertiary-text'
                }
              />
            ) : (
              'Partner User'
            )}
          </div>
        }
        className={'!bg-secondary-bg py-2.5 flex flex-col gap-2.5'}
        headerClasses={'font-segoe-ui font-semibold text-sm text-tertiary-text'}
      >
        <PartnerDetailsForm
          form={childForm}
          contacts={contacts}
          setContacts={setContacts}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          parentForm={form}
        />
      </Card>
    </Form>
  );
}
