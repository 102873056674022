export default function Label({
  containerClasses,
  label,
  labelClasses,
  description,
  descriptionClasses,
}) {
  return (
    <div className={containerClasses}>
      <span className={labelClasses}>{label}</span>
      <span className={descriptionClasses}>{description}</span>
    </div>
  );
}
